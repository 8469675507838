import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import './Inventario.css';


const Inventario = ({ propertyId, closeModal }) => {
  const [estadoParedes, setEstadoParedes] = useState('');
  const [inventario, setInventario] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const inventoryData = {
        propertyId,
        estadoParedes,
        inventario: inventario.split(',').map(item => item.trim()), // Convierte el texto en un array
      };
      await addDoc(collection(db, 'inventarios'), inventoryData);
      alert('Inventario guardado exitosamente');
      closeModal();
    } catch (error) {
      console.error('Error al guardar el inventario:', error);
      alert('Error al guardar el inventario');
    }
  };

  return (
    <div className="modal">
      <div className="container-inventario-form">
        <h2>Crear Inventario</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Estado de Paredes:</label>
            <input type="text" value={estadoParedes} onChange={(e) => setEstadoParedes(e.target.value)} className="form-control" />
          </div>
          <div>
            <label>Inventario:</label>
            <textarea value={inventario} onChange={(e) => setInventario(e.target.value)} className="form-control" />
            <small className="smallt">Separar cada ítem por comas.</small>
          </div>
          <button type="submit">Guardar Inventario</button>
          <button type="button" onClick={closeModal}>Cancelar</button>
        </form>
      </div>
    </div>
  );
};

export default Inventario;
