import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext.js';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading.js';
import Inventario from './Inventario.js'; // Importa el componente Inventario
import './Office.css';

const Office = () => {
  const { currentUser } = useAuth();
  const [properties, setProperties] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [propertiesPerPage] = useState(5);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null); // Estado para la propiedad seleccionada
  const navigate = useNavigate();

  // Obtener las propiedades del usuario
  useEffect(() => {
    const fetchProperties = async () => {
      if (currentUser) {
        const propertiesRef = collection(db, 'properties');
        const q = query(propertiesRef, where('idUsuario', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
        setProperties(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      }
    };

    fetchProperties();
  }, [currentUser]);

  // Búsqueda de propiedades por dirección o barrio
  const filteredProperties = properties.filter(property =>
    property.direccion.toLowerCase().includes(search.toLowerCase()) ||
    property.barrio.toLowerCase().includes(search.toLowerCase())
  );

  // Paginación
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

  // Función para cambiar página
  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  // Redirigir a la página de consultas o reservas
  const viewDetails = (property, type) => {
    if (type === 'reservas') {
      navigate(`/reservas/${property.id}`);
    } else if (type === 'consultas') {
      navigate(`/consultas/${property.id}`);
    }
  };

  // Abrir el modal de inventario para una propiedad específica
  const openInventoryModal = (property) => {
    setSelectedProperty(property);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProperty(null);
  };

  if (!currentUser) {
    return (
      <div id="carga" className="containerLoading">
        <Loading />
      </div>
    );
  }

  return (
    <div className="office-container">
      <div className="propietario-section">
        <h2>Tus Propiedades</h2>

        <input
          type="text"
          placeholder="Buscar por dirección o barrio..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="search-input-mis-prop"
        />

        {/* Lista de propiedades con paginación */}
        {currentProperties.map(property => (
          <div key={property.id} className="property-card">
            <h3>{property.direccion}</h3>
            {property.operacion === 'alquiler temporario' ? (
              <>
                <button onClick={() => viewDetails(property, 'reservas')}>Ver Reservas</button>
                <button onClick={() => openInventoryModal(property)}>Hacer Inventario</button>
              </>
            ) : (
              <button onClick={() => viewDetails(property, 'consultas')}>Ver Consultas</button>
            )}
          </div>
        ))}

        {/* Paginador con botones de anterior y siguiente */}
        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={prevPage} className="btn moli-o">Anterior</button>
          )}
          {currentPage < Math.ceil(filteredProperties.length / propertiesPerPage) && (
            <button onClick={nextPage} className="btn moli-o">Siguiente</button>
          )}
        </div>

        <div className="add-property">
          <h3>¿Deseas cargar otra propiedad?</h3>
          <a href="https://wa.me/xxxxxx" className="btn-wsp">Comunícate por WhatsApp</a>
        </div>
      </div>

      {/* Modal para el inventario */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button onClick={closeModal} className="modal-close">&times;</button>
            <Inventario property={selectedProperty} /> {/* Pasa la propiedad seleccionada al componente Inventario */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Office;
