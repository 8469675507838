import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig.js';
import { collection, getDocs } from 'firebase/firestore';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DatePicker, Select, Table } from 'antd';
import moment from 'moment';

ChartJS.register(...registerables);

const getWeek = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const SearchAnalytics = () => {
  const [weeklySearchData, setWeeklySearchData] = useState(0);
  const [monthlySearchData, setMonthlySearchData] = useState(0);
  const [propertyTypeDistribution, setPropertyTypeDistribution] = useState({});
  const [roomDistribution, setRoomDistribution] = useState({});
  const [neighborhoodData, setNeighborhoodData] = useState({ labels: [], data: [] });
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState('');
  const [selectedPropertyType, setSelectedPropertyType] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    fetchData();
  }, [dateRange, selectedNeighborhood, selectedPropertyType]);

  const fetchData = async () => {
    const searchCollection = collection(db, 'busquedas');
    const searchesSnapshot = await getDocs(searchCollection);
  
    let weeklyCount = 0;
    let monthlyCount = 0;
    let propertyTypeCounts = {};
    let roomCounts = { '1': 0, '2': 0, '3': 0, '4+': 0 };
    let neighborhoodCounts = {};
    let filteredResults = [];

    const now = new Date();
    const currentWeek = getWeek(now);
    const currentMonth = now.getMonth();
  
    searchesSnapshot.forEach((doc) => {
      const searchData = doc.data();
  
      if (searchData.createdAt) {
        const searchDate = searchData.createdAt.toDate();
  
        const inDateRange =
          (!dateRange || !dateRange[0] || searchDate >= dateRange[0]) &&
          (!dateRange || !dateRange[1] || searchDate <= dateRange[1]);
  
        if (inDateRange) {
          if (getWeek(searchDate) === currentWeek) weeklyCount++;
          if (searchDate.getMonth() === currentMonth) monthlyCount++;
  
          const type = searchData.tipoPropiedad || "Otro";
          propertyTypeCounts[type] = (propertyTypeCounts[type] || 0) + 1;
  
          const rooms = searchData.cantidadAmbientes;
          if (rooms > 3) roomCounts['4+']++;
          else if (rooms) roomCounts[rooms]++;
  
          const neighborhoods = searchData.zonas
            ? searchData.zonas.split(',').map(b => b.trim())
            : ['No especifica'];
  
          neighborhoods.forEach(neighborhood => {
            neighborhoodCounts[neighborhood] = (neighborhoodCounts[neighborhood] || 0) + 1;
          });
  
          if (
            (!selectedNeighborhood || neighborhoods.includes(selectedNeighborhood)) &&
            (!selectedPropertyType || searchData.tipoPropiedad === selectedPropertyType) &&
            inDateRange
          ) {
            filteredResults.push(searchData);
          }
        }
      }
    });
  
    setWeeklySearchData(weeklyCount);
    setMonthlySearchData(monthlyCount);
    setPropertyTypeDistribution(propertyTypeCounts);
    setRoomDistribution(roomCounts);
    setNeighborhoodData({
      labels: Object.keys(neighborhoodCounts),
      data: Object.values(neighborhoodCounts),
    });
    setSearchResults(filteredResults);
  };
  
  const handleDateChange = (dates) => {
    setDateRange(dates ? dates : [null, null]);
  };

  const handleNeighborhoodChange = (value) => {
    setSelectedNeighborhood(value);
  };

  const handlePropertyTypeChange = (value) => {
    setSelectedPropertyType(value);
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    doc.text("Search Analytics Report", 10, 10);
    doc.text(`Búsquedas esta semana: ${weeklySearchData}`, 10, 20);
    doc.text(`Búsquedas este mes: ${monthlySearchData}`, 10, 30);

    const canvasElements = document.getElementsByTagName('canvas');
    let yOffset = 50;

    for (let i = 0; i < canvasElements.length; i++) {
      const canvas = canvasElements[i];
      const imgData = canvas.toDataURL('image/png');
      
      if (yOffset + 180 > doc.internal.pageSize.height) {
        doc.addPage();
        yOffset = 10;
      }

      doc.addImage(imgData, 'PNG', 10, yOffset, 180, 180);
      yOffset += 190;
    }

    doc.save("search-analytics.pdf");
  };

  const columns = [
    {
      title: 'Tipo de Propiedad',
      dataIndex: 'tipoPropiedad',
      key: 'tipoPropiedad',
    },
    {
      title: 'Cantidad de Ambientes',
      dataIndex: 'cantidadAmbientes',
      key: 'cantidadAmbientes',
    },
    {
      title: 'Presupuesto Máximo',
      dataIndex: 'presupuestoMaximo',
      key: 'presupuestoMaximo',
      render: (text) => `$${text}`,
    },
    {
      title: 'Zonas',
      dataIndex: 'zonas',
      key: 'zonas',
    },
    {
      title: 'Fecha de Búsqueda',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => moment(text.toDate()).format('YYYY-MM-DD'),
    },
  ];

  return (
    <div className="search-analytics">
      <h2>Análisis de Búsquedas</h2>

      <div className="analytics-section">
        <h3>Selector de Fechas</h3>
        <DatePicker.RangePicker onChange={handleDateChange} format="YYYY-MM-DD" value={dateRange} />
      </div>

      <div className="analytics-section">
        <h3>Tipo de Propiedad y Zona</h3>
        <Select
          placeholder="Seleccionar Tipo de Propiedad"
          onChange={handlePropertyTypeChange}
          style={{ width: 200, marginRight: '10px' }}
        >
          <Select.Option value="Casa">Casa</Select.Option>
          <Select.Option value="Departamento">Departamento</Select.Option>
          <Select.Option value="Local">Local</Select.Option>
        </Select>

        <Select
          placeholder="Seleccionar Zona"
          onChange={handleNeighborhoodChange}
          style={{ width: 200 }}
        >
          {neighborhoodData.labels.map((neighborhood) => (
            <Select.Option key={neighborhood} value={neighborhood}>
              {neighborhood}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div className="analytics-section">
        <h3>Resultados de Búsqueda</h3>
        <Table
          columns={columns}
          dataSource={searchResults}
          rowKey="id"
        />
      </div>

      <div className="chart-container">
        <h3>Distribución por Tipo de Propiedad</h3>
        <Pie
          data={{
            labels: Object.keys(propertyTypeDistribution),
            datasets: [
              {
                label: 'Distribución por Tipo',
                data: Object.values(propertyTypeDistribution),
                backgroundColor: [
                  '#FF6384', '#36A2EB', '#FFCE56', '#66ff66', '#9966ff'
                ],
              },
            ],
          }}
        />
      </div>

      <div className="chart-container">
        <h3>Distribución por Cantidad de Ambientes</h3>
        <Bar
          data={{
            labels: Object.keys(roomDistribution),
            datasets: [
              {
                label: 'Cantidad',
                data: Object.values(roomDistribution),
                backgroundColor: '#36A2EB',
              },
            ],
          }}
        />
      </div>

      <div className="chart-container">
        <h3>Distribución por Zona</h3>
        <Bar
          data={{
            labels: neighborhoodData.labels,
            datasets: [
              {
                label: 'Búsquedas',
                data: neighborhoodData.data,
                backgroundColor: '#FFCE56',
              },
            ],
          }}
        />
      </div>

      <button onClick={generatePDF}>Descargar PDF</button>
    </div>
  );
};

export default SearchAnalytics;
