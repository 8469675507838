import React, { useState, useEffect } from 'react';
import Office from './components/Office.js';
import OfficeAsesor from './components/OfficeAsesor.js';
import OfficeInm from './components/OfficeInm.js'; // Asegúrate de importar el nuevo componente
import Menu from './components/Menu.js';
import RoleSelection from './components/RoleSelection.js'; // Nuevo componente para seleccionar el rol
import { useAuth } from './context/AuthContext.js'; // Para obtener el usuario actual
import { db } from './firebaseConfig.js'; // Asegúrate de la ruta correcta
import { doc, getDoc } from 'firebase/firestore';

const MiOficina = () => {
  const { currentUser } = useAuth();
  const [role, setRole] = useState(null);

  // Obtener el rol del usuario desde Firestore
  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            setRole(userDoc.data().role);
          } else {
            console.error("No se encontró el documento del usuario.");
          }
        } catch (error) {
          console.error("Error al obtener el rol del usuario: ", error);
        }
      }
    };

    fetchUserRole();
  }, [currentUser]);

  // Actualizar el rol según la selección del usuario
  const handleRoleSelection = (selectedRole) => {
    setRole(selectedRole);
    // Aquí podrías actualizar el rol en la base de datos si es necesario
  };

  return (
    <div className="vp-container">
      <div className="vp-main-content">
        {role === 'user' && <RoleSelection onSelectRole={handleRoleSelection} />} {/* Pregunta si es propietario o asesor */}
        {role === 'propietario' && <Office />} {/* Si es propietario, muestra el contenido para propietarios */}
        {role === 'asesor' && <OfficeAsesor />} {/* Si es asesor, muestra la oficina del asesor */}
        {role === 'inmobiliaria' && <OfficeInm />} {/* Si es inmobiliaria, muestra la oficina inmobiliaria */}
      </div>
      <Menu />
    </div>
  );
};

export default MiOficina;
