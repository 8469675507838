import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig.js';
import { collection, getDocs, query, where, deleteDoc, doc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext.js';
import EditClient from './EditClient.js';

const ClientTable = () => {
  const [clients, setClients] = useState([]);
  const { currentUser } = useAuth();

  const [selectedClient, setSelectedClient] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 20;
  const [searchTerm, setSearchTerm] = useState(''); // Asegúrate de que esté bien inicializado

  // Función para obtener clientes
  const fetchClients = async () => {
    if (currentUser) {
      const clientCollection = collection(db, 'clientes');
      const q = query(clientCollection, where('creadoPor', '==', currentUser.uid));
      const clientSnapshot = await getDocs(q);
      const clientList = clientSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientList);
      checkContactAlerts(clientList); // Verifica alertas después de obtener los clientes
    }
  };

  useEffect(() => {
    fetchClients(); // Llama a la función al cargar el componente
  }, [currentUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      const today = new Date();
      clients.forEach(client => {
        if (client.nextContact && (new Date(client.nextContact) - today) <= 0) {
          alert(`Es hora de contactar a ${client.nombre}.`);
        }
      });
    }, 86400000); // Comprueba cada 24 horas

    return () => clearInterval(interval); // Limpia el intervalo al desmontar
  }, [clients]);

  const handleDelete = async (clientId) => {
    if (window.confirm("¿Estás seguro de que deseas eliminar este cliente?")) {
      try {
        await deleteDoc(doc(db, 'clientes', clientId));
        setClients(clients.filter(client => client.id !== clientId));
        alert('Cliente eliminado con éxito');
      } catch (error) {
        console.error('Error al eliminar cliente:', error);
        alert('Hubo un error al eliminar el cliente');
      }
    }
  };

  const handleView = (client) => {
    setSelectedClient(client);
  };

  const handleEdit = (client) => {
    setSelectedClient(client);
    setIsEditing(true);
  };

  const handleClose = () => {
    setSelectedClient(null);
    setIsEditing(false);
  };

  const handleClientUpdated = async () => {
    setSelectedClient(null);
    setIsEditing(false);
    await fetchClients(); // Refrescar la lista de clientes
  };

  const checkContactAlerts = (clients) => {
    const today = new Date();
    clients.forEach(client => {
      if (client.nextContact && (new Date(client.nextContact) - today) <= 0) {
        alert(`Es hora de contactar a ${client.nombre}.`);
      }
      if (client.lastContacted && (today - new Date(client.lastContacted)) / (1000 * 3600 * 24) > 21) {
        alert(`Recuerda contactar a ${client.nombre}. Han pasado más de 21 días desde el último contacto.`);
      }
    });
  };

  const filteredClients = clients.filter(client =>
    client.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);
  const totalPages = Math.ceil(filteredClients.length / clientsPerPage);

  return (
    <div className="client-table">
      <h2>Lista de Clientes</h2>
      <input
        type="text"
        className="search-input-mis-prop"
        placeholder="Buscar cliente..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Nombre Completo</th>
            <th>Propiedad de Interés</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentClients.map((client) => (
            <tr key={client.id}>
              <td>{client.nombre}</td>
              <td className="celdaLarga">{client.propertyInterest}</td>
              <td>{client.status}</td>
              <td className="filaIconsTable">
                <i className="fas fa-eye moli-o" onClick={() => handleView(client)}></i>
                <i className="fas fa-edit moli-o" onClick={() => handleEdit(client)}></i>
                <i className="fas fa-trash-alt moli-o" onClick={() => handleDelete(client.id)}></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <nav>
        <ul className="pagination">
          {[...Array(totalPages)].map((_, index) => (
            <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button onClick={() => paginate(index + 1)} className="btn moli-o">
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {selectedClient && !isEditing && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleClose}>&times;</span>
            <h2>Detalles del Cliente</h2>
            <p><strong>Nombre:</strong> {selectedClient.nombre}</p>
            <p><strong>Email:</strong> {selectedClient.email}</p>
            <p><strong>Teléfono:</strong>
              <a href={`https://wa.me/${selectedClient.phone}`} target="_blank" rel="noopener noreferrer"> {selectedClient.phone} </a>
            </p>
            <p><strong>Dirección:</strong> {selectedClient.address}</p>
            <p><strong>Preferencia de Contacto:</strong> {selectedClient.contactPreference}</p>
            <p><strong>Propiedad de Interés:</strong> {selectedClient.propertyInterest}</p>
            <p><strong>Presupuesto:</strong> {selectedClient.budget}</p>
            <p><strong>Estado:</strong> {selectedClient.status}</p>
            <p><strong>Notas:</strong> {selectedClient.notes}</p>
            <p><strong>Siguiente Contacto:</strong> {selectedClient.nextContact}</p>
          </div>
        </div>
      )}

      {selectedClient && isEditing && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleClose}>&times;</span>
            <EditClient client={selectedClient} onClientUpdated={handleClientUpdated} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientTable;
