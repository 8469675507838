import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig.js';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { storage } from '../firebaseConfig.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const Settings = ({ inmobiliariaId }) => {
  const [formData, setFormData] = useState({
    portada: '',
    especialidades: [],  // Asegúrate de que sea un array vacío
    biografia: '',
    porcentajeClientes: '',
    porcentajeAsesores: '',
    direccion: '',
  });

  const especialidadesOpciones = [
    'campos', 'departamentos', 'casas', 'high-ticket',
    'alquileres-temporarios', 'ph', 'construcciones-al-pozo',
    'lotes', 'locales', 'medium-ticket', 'small-ticket',
  ];

  useEffect(() => {
    const fetchInmobiliariaData = async () => {
      const inmobiliariaDoc = await getDoc(doc(db, 'inmobiliarias', inmobiliariaId));
      if (inmobiliariaDoc.exists()) {
        const data = inmobiliariaDoc.data();
        setFormData({
          ...data,
          especialidades: data.especialidades || [], // Asegúrate de que siempre sea un array
        });
      }
    };

    fetchInmobiliariaData();
  }, [inmobiliariaId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prev) => {
      const newEspecialidades = checked
        ? [...prev.especialidades, value]
        : prev.especialidades.filter((esp) => esp !== value);
      return { ...prev, especialidades: newEspecialidades };
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const storageRef = ref(storage, `portadas/${file.name}`);
      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        setFormData((prev) => ({
          ...prev,
          portada: downloadURL,
        }));
      } catch (error) {
        console.error('Error al subir la imagen: ', error);
        alert('Error al cargar la imagen de la portada.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const inmobiliariaRef = doc(db, 'inmobiliarias', inmobiliariaId);
      await updateDoc(inmobiliariaRef, formData);
      alert('Configuración actualizada exitosamente!');
    } catch (error) {
      console.error('Error al actualizar la inmobiliaria: ', error);
      alert('Error al actualizar la configuración.');
    }
  };

  return (
    <div className="add-property">
      <h2>Configuración de Inmobiliaria</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="portada">Portada:</label>
          <input type="file" name="portada" onChange={handleFileChange} required />
        </div>
        
        <div className="form-group">
          <label>Especialidades:</label>
          <div className="inputGroupCheckox">
          {especialidadesOpciones.map((opcion) => (
            <div key={opcion} className="inputCa">
              <input
                type="checkbox"
                value={opcion}
                checked={formData.especialidades.includes(opcion)}
                onChange={handleCheckboxChange}
              />
              <label>{opcion}</label>
            </div>
          ))}
          </div> 
        </div>

        <div className="form-group">
          <label htmlFor="biografia">Biografía/Descripción:</label>
          <textarea
            name="biografia"
            className="form-control"
            value={formData.biografia}
            onChange={handleChange}
            required
            placeholder="Descripción de la inmobiliaria"
          />
        </div>

        <div className="form-group">
          <label htmlFor="porcentajeClientes">% a personas que comparten clientes:</label>
          <input
            type="number" className="form-control"
            name="porcentajeClientes"
            value={formData.porcentajeClientes}
            onChange={handleChange}
            placeholder="Porcentaje"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="porcentajeAsesores">% para asesores independientes:</label>
          <input
            type="number" className="form-control"
            name="porcentajeAsesores"
            value={formData.porcentajeAsesores}
            onChange={handleChange}
            placeholder="Porcentaje"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="direccion">Dirección de la oficina:</label>
          <input
            type="text" className="form-control"
            name="direccion"
            value={formData.direccion}
            onChange={handleChange}
            placeholder="Dirección de la oficina"
            required
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Guardar Cambios
        </button>
      </form>
    </div>
  );
};

export default Settings;
