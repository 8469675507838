import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig.js'; // Asegúrate de que la ruta sea correcta
import { collection, getDocs } from 'firebase/firestore';

const InmobiliariasList = () => {
  const [inmobiliarias, setInmobiliarias] = useState([]);

  useEffect(() => {
    const fetchInmobiliarias = async () => {
      const inmobiliariasCollection = collection(db, 'inmobiliarias');
      const inmobiliariasSnapshot = await getDocs(inmobiliariasCollection);
      const inmobiliariasList = inmobiliariasSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setInmobiliarias(inmobiliariasList);
    };

    fetchInmobiliarias();
  }, []);

  return (
    <div>
      <h3>Lista de Inmobiliarias</h3>
      <ul>
        {inmobiliarias.map(inmobiliaria => (
          <li key={inmobiliaria.id}>
            {inmobiliaria.nombre} - {inmobiliaria.registro} - {inmobiliaria.ciudad}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InmobiliariasList;
