import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../firebaseConfig.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const EditeProperty = ({ property, closeModal, onSave }) => {
  const [editForm, setEditForm] = useState({ ...property });
  const [draggedFileIndex, setDraggedFileIndex] = useState(null);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newFiles = files.map(file => ({
      fileName: file.name,
      fileURL: URL.createObjectURL(file),
      fileObject: file // Almacenar el archivo para cargarlo a Firebase
    }));
    
    // Reemplazar las imágenes actuales con las nuevas
    setEditForm({
      ...editForm,
      archivos: newFiles
    });
  };
  

  const handleSave = async () => {
    try {
      const newFileURLs = [];
      for (const file of editForm.archivos) {
        if (file.fileObject) { // Solo subimos los archivos nuevos
          const imageRef = ref(storage, file.fileName);
          await uploadBytes(imageRef, file.fileObject);
          const fileURL = await getDownloadURL(imageRef);
          newFileURLs.push({ fileName: file.fileName, fileURL });
        } else {
          newFileURLs.push(file); // Archivos existentes se conservan
        }
      }

      await updateDoc(doc(db, 'properties', property.id), {
        ...editForm,
        archivos: newFileURLs
      });

      onSave({ ...editForm, archivos: newFileURLs });
    } catch (error) {
      console.error('Error updating property:', error);
    }
  };

  // Funciones de arrastre
  const handleDragStart = (index) => {
    setDraggedFileIndex(index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (index) => {
    const updatedArchivos = [...editForm.archivos];
    const [draggedItem] = updatedArchivos.splice(draggedFileIndex, 1);
    updatedArchivos.splice(index, 0, draggedItem);
    setEditForm({ ...editForm, archivos: updatedArchivos });
    setDraggedFileIndex(null);
  };

  const handleInputChange = (field, value) => {
    setEditForm({ ...editForm, [field]: value });
  };

  const handleRemoveFile = (index) => {
    const updatedArchivos = editForm.archivos.filter((_, i) => i !== index);
    setEditForm({ ...editForm, archivos: updatedArchivos });
  };
  

  return (
    <div className="modal">
      <div className="container-edite-form">
        <h2>Editar Propiedad</h2>
        <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
          <label>Dirección</label>
          <input 
            type="text" 
            className="form-control"
            value={editForm.direccion} 
            onChange={(e) => handleInputChange('direccion', e.target.value)}
          />

          <label>Descripción</label>
          <textarea
            className="form-control texareaedite"
            value={editForm.descrip} 
            onChange={(e) => handleInputChange('descrip', e.target.value)}
          ></textarea>

          <label>Archivos</label>
          <input type="file" className="form-control" multiple onChange={handleFileChange} />

          <div className="file-previews">
            {editForm.archivos.map((file, index) => (
              <div
                key={file.fileName}
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(index)}
                className="file-preview"
              >
                <img src={file.fileURL} alt="Preview" />
                <button
                    onClick={() => handleRemoveFile(index)} // Manejar la eliminación
                    className="remove-file-button"
                    aria-label="Eliminar imagen"
                >
                    &times; {/* Este es el símbolo para la "X" */}
                </button>
              </div>
            ))}
          </div>

          {/* Campos de Texto */}
          <div className="inputText">
            <div className="form-group">
                <label>Ambientes</label>
                <input type="number" className="form-control" value={editForm.amb || ''} onChange={(e) => handleInputChange('amb', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Barrio</label>
                <input type="text" className="form-control" value={editForm.barrio || ''} onChange={(e) => handleInputChange('barrio', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Ciudad</label>
                <input type="text" className="form-control" value={editForm.ciudad || ''} onChange={(e) => handleInputChange('ciudad', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Baños</label>
                <input type="number" className="form-control" value={editForm.banos || ''} onChange={(e) => handleInputChange('banos', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Dormitorios</label>
                <input type="number" className="form-control" value={editForm.dormitorios || ''} onChange={(e) => handleInputChange('dormitorios', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Estado</label>
                <input type="text" className="form-control" value={editForm.estado || ''} onChange={(e) => handleInputChange('estado', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Llaves</label>
                <input type="text" className="form-control" value={editForm.llaves || ''} onChange={(e) => handleInputChange('llaves', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Metros Cubiertos</label>
                <input type="number" className="form-control" value={editForm.mcub || ''} onChange={(e) => handleInputChange('mcub', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Metros Descubiertos</label>
                <input type="number" className="form-control" value={editForm.mdes || ''} onChange={(e) => handleInputChange('mdes', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Orientación</label>
                <input type="text" className="form-control" value={editForm.orientacion || ''} onChange={(e) => handleInputChange('orientacion', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Tipo</label>
                <input type="text" className="form-control" value={editForm.tipo || ''} onChange={(e) => handleInputChange('tipo', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Publicación</label>
                <input type="text" className="form-control" value={editForm.publicacion || ''} onChange={(e) => handleInputChange('publicacion', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Vista</label>
                <input type="text" className="form-control" value={editForm.vista || ''} onChange={(e) => handleInputChange('vista', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Expensas</label>
                <input type="number" className="form-control" value={editForm.expensas || ''} onChange={(e) => handleInputChange('expensas', e.target.value)} />
            </div>
            <div className="form-group">
                <label>Antigüedad</label>
                <input type="number" className="form-control" value={editForm.antiguedad || ''} onChange={(e) => handleInputChange('antiguedad', e.target.value)} />
            </div>
          </div>
          {/* Campos Booleanos */}
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={editForm.quincho || false}
                onChange={(e) => handleInputChange('quincho', e.target.checked)}
              />
              Quincho
            </label>
            <label>
              <input
                type="checkbox"
                checked={editForm.piscina || false}
                onChange={(e) => handleInputChange('piscina', e.target.checked)}
              />
              Piscina
            </label>
            <label>
              <input
                type="checkbox"
                checked={editForm.permuta || false}
                onChange={(e) => handleInputChange('permuta', e.target.checked)}
              />
              Permuta
            </label>
            <label>
              <input
                type="checkbox"
                checked={editForm.patio || false}
                onChange={(e) => handleInputChange('patio', e.target.checked)}
              />
              Patio
            </label>
            <label>
              <input
                type="checkbox"
                checked={editForm.parque || false}
                onChange={(e) => handleInputChange('parque', e.target.checked)}
              />
              Parque
            </label>
            <label>
              <input
                type="checkbox"
                checked={editForm.dep || false}
                onChange={(e) => handleInputChange('dep', e.target.checked)}
              />
              Dependencia
            </label>
            <label>
              <input
                type="checkbox"
                checked={editForm.credito || false}
                onChange={(e) => handleInputChange('credito', e.target.checked)}
              />
              Apto Crédito
            </label>
            <label>
              <input
                type="checkbox"
                checked={editForm.cochera || false}
                onChange={(e) => handleInputChange('cochera', e.target.checked)}
              />
              Cochera
            </label>
            <label>
              <input
                type="checkbox"
                checked={editForm.baulera || false}
                onChange={(e) => handleInputChange('baulera', e.target.checked)}
              />
              Baulera
            </label>
          </div>

          <button type="submit" className="btn btn-primary">Guardar Cambios</button>
          <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancelar</button>
        </form>
      </div>
    </div>
  );
};

export default EditeProperty;
