import React, { useState } from 'react';
import { db } from '../firebaseConfig.js';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext.js';

const AddClient = ({ onClientAdded = () => {} }) => {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    phone: '',
    address: '',
    contactPreference: 'email',
    propertyInterest: '',
    budget: '',
    status: 'activo',
    notes: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const checkExistingClient = async () => {
    const clientCollection = collection(db, 'clientes');
    const emailQuery = query(clientCollection, where('email', '==', formData.email), where('creadoPor', '==', currentUser.uid));
    const phoneQuery = query(clientCollection, where('phone', '==', formData.phone), where('creadoPor', '==', currentUser.uid));

    const emailSnapshot = await getDocs(emailQuery);
    const phoneSnapshot = await getDocs(phoneQuery);

    return !emailSnapshot.empty || !phoneSnapshot.empty;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      alert('No estás autenticado.');
      return;
    }

    // Verificar si el cliente ya existe
    const clientExists = await checkExistingClient();
    if (clientExists) {
      alert('Ya has agregado un cliente con este email o teléfono.');
      return;
    }

    try {
      // Guardar el cliente en Firestore
      await addDoc(collection(db, 'clientes'), {
        ...formData,
        creadoPor: currentUser.uid,
        createdAt: new Date(),
        lastContacted: new Date(), // Fecha del último contacto
        nextContact: '', // Fecha del próximo contacto
      });
      onClientAdded();
      alert('Cliente agregado con éxito');
      setFormData({
        nombre: '',
        email: '',
        phone: '',
        address: '',
        contactPreference: 'email',
        propertyInterest: '',
        budget: '',
        status: 'activo',
        notes: '',
      });
    } catch (error) {
      console.error('Error al agregar cliente: ', error);
      alert('Hubo un error al agregar el cliente');
    }
  };

  return (
    <div className="add-client-form add-property">
      <h2>Agregar Nuevo Cliente</h2>
      <form onSubmit={handleSubmit}>
        {/* Aquí se repite el formulario para agregar un cliente, igual que en tu código */}
        <div className="form-group">
          <label>Nombre Completo</label>
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Teléfono</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Dirección</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Preferencia de Contacto</label>
          <select
            name="contactPreference"
            value={formData.contactPreference}
            onChange={handleChange}
            className="form-control"
          >
            <option value="email">Email</option>
            <option value="whatsapp">WhatsApp</option>
            <option value="telefono">Teléfono</option>
          </select>
        </div>

        <div className="form-group">
          <label>Propiedades de Interés</label>
          <input
            type="text"
            name="propertyInterest"
            value={formData.propertyInterest}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Presupuesto</label>
          <input
            type="number"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Estado del Cliente</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="form-control"
          >
            <option value="activo">Activo</option>
            <option value="negociacion">En Negociación</option>
            <option value="cerrado">Cerrado</option>
            <option value="perdido">Perdido</option>
          </select>
        </div>

        <div className="form-group">
          <label>Notas</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Agregar Cliente
        </button>
      </form>
    </div>
  );
};

export default AddClient;
