import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext.js';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig.js';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import './PropertyCard.css';

const PropertyCard = ({ property, archivos = [] }) => {
  const [consulta, setConsulta] = useState('');
  const { currentUser } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [shareOpen, setShareOpen] = useState(false);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % archivos.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + archivos.length) % archivos.length);
  };

  const handleConsultaChange = (e) => {
    setConsulta(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      alert('Debes estar logueado para enviar una consulta.');
      return;
    }

    if (!consulta.trim()) {
      alert('La consulta no puede estar vacía.');
      return;
    }


    try {
      await addDoc(collection(db, 'consultas'), {
        idPropiedad: property.id,
        tipoPropiedad: property.tipo || 'Tipo no disponible',
        direccion: property.direccion || 'Dirección no disponible',
        barrio: property.barrio || 'Barrio no disponible',
        ciudad: property.ciudad || 'Ciudad no disponible',
        nombreUsuario: currentUser.displayName || 'Desconocido',
        telefono: currentUser.phoneNumber || 'No disponible',
        email: currentUser.email || 'No disponible',
        consulta,
        codigoReferido: currentUser?.referido || 'No disponible',
        createdAt: Timestamp.now(),
      });

      const mensaje = `Consulta sobre la propiedad: ${property.direccion}, ${property.barrio}, ${property.ciudad}: ${consulta}`;
      const numeroWhatsApp = '5492233390092';
      window.open(`https://wa.me/${numeroWhatsApp}?text=${encodeURIComponent(mensaje)}`, '_blank');

      setConsulta('');
      alert('Consulta enviada exitosamente.');
    } catch (error) {
      console.error('Error al enviar la consulta: ', error);
      alert('Hubo un error al enviar la consulta.');
    }
  };

  const handleShare = () => {
    setShareOpen(!shareOpen);
  };

  const handleCopyLink = () => {
    const url = `${window.location.origin}/property/${property.id}?ref=${currentUser?.uid}`;
    navigator.clipboard.writeText(url).then(() => {
      alert('Enlace copiado al portapapeles');
    });
  };

  const shareUrl = `${window.location.origin}/property/${property.id}?ref=${currentUser?.uid}`;
  const city = property.ciudad || 'Ciudad no disponible';

  return (
    <div className="cont-bus-prop">
      <div className="carousel">
        <div className="carousel-inner">
          {archivos.map((img, index) => (
            <div
              key={index}
              className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
              style={{ transform: `rotateY(${index === currentIndex ? '0deg' : '90deg'})` }}
            >
              <img src={img.fileURL} className="d-block w-100 imgprop" alt={`${property.tipo} ${property.amb} en ${property.barrio} - ${property.ciudad}`} />
            </div>
          ))}
        </div>
        <i className="fa-solid fa-chevron-left carousel-control-prev" onClick={prevSlide}></i>
        <i className="fa-solid fa-chevron-right carousel-control-next" onClick={nextSlide}></i>
      </div>

      <div className="titulo-propiedad">
        <h2>{`${property.tipo} ${property.amb} ambientes en ${property.barrio} - USD ${property.publicacion}`}</h2>
      </div>

      <div className="botones-prop">
        <ul>
          <li className="li-prop" onClick={handleShare}><i className="fa-solid fa-share-from-square"></i></li>
          <li className="li-prop" onClick={() => navigate(`/property/${property.id}`)}><span>Ver</span></li>
        </ul>
      </div>

      {shareOpen && (
      <div className="conteinerIconShare">  
        <h3>Compartí esta propiedad y ganá recompensas en dólares</h3>
        <p>Compartí las propiedades del sitio, si una persona se registra desde tu enlace, recibirás recompensas cuando realice su primer compra, venta o alquiler.</p>
        <ul className="wrapper-icons sci">
          <li className="icon comentarios">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=https://reservos.com/property/${property.id}?referrer=${currentUser?.uid || ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="fa-brands fa-facebook"></i>
              </span>
            </a>
          </li>
          <li className="icon comentarios">
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=https://reservos.com/property/${property.id}?referrer=${currentUser?.uid || ''}&title=Encontrá%20las%20mejores%20oportunidades%20inmobiliarias`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="fa-brands fa-linkedin"></i>
              </span>
            </a>
          </li>
          <li className="icon comentarios">
            <a
              href={`https://twitter.com/intent/tweet?text=Encontrá%20las%20mejores%20oportunidades%20inmobiliarias%20https://reservos.com/property/${property.id}?referrer=${currentUser?.uid || ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="fa-brands fa-x-twitter"></i>
              </span>
            </a>
          </li>
          <li className="icon comentarios">
            <a
              href={`https://api.whatsapp.com/send?text=https://reservos.com/property/${property.id}?referrer=${currentUser?.uid || ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="fa-brands fa-whatsapp"></i>
              </span>
            </a>
          </li>
          <li className="icon comentarios" onClick={handleCopyLink}>
            <span>
              <i className="fa-solid fa-link"></i>
            </span>
          </li>
        </ul> 
      </div>     
      )}

      <div className="contenerdor-consulta-prop">
        <form className="input-group input-consul" onSubmit={handleSubmit}>
          <input
            type="text"
            className="form-control input-bus-real"
            placeholder="Haz una consulta privada"
            value={consulta}
            onChange={handleConsultaChange}
          />
          <button type="submit" className="btn moli-o">Enviar</button>
        </form>
      </div>
    </div>
  );
};

export default PropertyCard;
