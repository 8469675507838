import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db } from './firebaseConfig.js';
import PropertyCard from './components/PropertyCard.js'; // Asegúrate de tener este componente
import Menu from './components/Menu.js'; // Asegúrate de tener el componente Menu
import Loading from './components/Loading.js';

const FilteredProperties = () => {
  const { filter } = useParams(); // Obtenemos el filtro de la URL
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para el mensaje de carga
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 9;

  // Calculamos la paginación
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = properties.slice(indexOfFirstProperty, indexOfLastProperty);

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true); // Mostrar mensaje de carga mientras se obtienen los datos
      let q;

      // Definimos la consulta según el filtro
      if (filter === 'reciclados-a-estrenar') {
        q = query(collection(db, 'properties'), where('estado', 'in', ['Reciclado', 'A estrenar']));
      } else if (filter === 'solo-casas') {
        q = query(collection(db, 'properties'), where('tipo', '==', 'Casa'));
      } else if (filter === 'oportunidades') {
        q = query(collection(db, 'properties'), where('publicacion', '<=', 1300));
      } else if (filter === 'vistas') {
        q = query(collection(db, 'properties'), where('vista', 'in', ['Al mar', 'panoramica']));
      }

      try {
        const querySnapshot = await getDocs(q);
        const propertyList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProperties(propertyList);
      } catch (error) {
        console.error("Error al obtener las propiedades: ", error);
      } finally {
        setLoading(false); // Desactivar el mensaje de carga cuando los datos estén listos
      }
    };

    fetchProperties();
  }, [filter]);

  // Función para ir a la página siguiente y desplazarse hacia arriba
  const nextPage = () => {
    if (currentPage < Math.ceil(properties.length / propertiesPerPage)) {
      setCurrentPage(prevPage => prevPage + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Desplazarse al inicio de la página
    }
  };

  // Función para ir a la página anterior y desplazarse hacia arriba
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Desplazarse al inicio de la página
    }
  };

  return (
    <div className="vp-container">
      <div className="property-list-container vp-main-content">
        <h1>Propiedades en venta</h1>

        {loading ? (
          <div id="carga" className="containerLoading">
            <Loading />
          </div> // Mostrar mensaje de carga
        ) : currentProperties.length > 0 ? (
          <ul className="property-list">
            {currentProperties.map((property) => (
              <PropertyCard key={property.id} property={property} archivos={property.archivos || []} />
            ))}
          </ul>
        ) : (
          <p>No hay propiedades que coincidan con el filtro.</p>
        )}

        {/* Paginación */}
        <div className="pagination">
          {currentPage > 1 && (
            <button onClick={prevPage}>
              Anterior
            </button>
          )}
          <button onClick={nextPage} disabled={currentPage === Math.ceil(properties.length / propertiesPerPage)}>
            Siguiente
          </button>
        </div>
      </div>
      <Menu />
    </div>
  );
};

export default FilteredProperties;
