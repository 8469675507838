import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig.js';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
import emailjs from 'emailjs-com';

const EmailMarketing = () => {
  const [userGroup, setUserGroup] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userGroup) fetchRecipients();
  }, [userGroup]);

  const fetchRecipients = async () => {
    setLoading(true);
    let users = [];
    const usersCollection = collection(db, 'users');

    try {
      // Cargar usuarios según el grupo seleccionado
      if (userGroup === 'all') {
        const allUsers = await getDocs(usersCollection);
        users = allUsers.docs.map((doc) => doc.data().email);
      } else if (userGroup === 'active') {
        const oneWeekAgo = Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
        const searchQuery = query(collection(db, 'busquedas'), where('date', '>=', oneWeekAgo));
        const recentSearches = await getDocs(searchQuery);
        const activeUserIds = new Set(recentSearches.docs.map((doc) => doc.data().uid));
        
        const activeUsersQuery = query(usersCollection, where('uid', 'in', Array.from(activeUserIds)));
        const activeUsersSnapshot = await getDocs(activeUsersQuery);
        users = activeUsersSnapshot.docs.map((doc) => doc.data().email);
      } else if (userGroup === 'inactive') {
        const oneMonthAgo = Timestamp.fromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
        const searchQuery = query(collection(db, 'busquedas'), where('date', '>=', oneMonthAgo));
        const recentSearches = await getDocs(searchQuery);
        const activeUserIds = new Set(recentSearches.docs.map((doc) => doc.data().uid));

        const allUsers = await getDocs(usersCollection);
        users = allUsers.docs
          .filter((doc) => !activeUserIds.has(doc.id))
          .map((doc) => doc.data().email);
      }
      setRecipients(users);
    } catch (error) {
      console.error("Error fetching recipients:", error);
    } finally {
      setLoading(false);
    }
  };

  const sendEmail = () => {
    const emailPromises = recipients.map((email) => {
      return emailjs.send(
        'your_service_id',
        'your_template_id',
        {
          subject,
          message,
          recipient_email: email,
        },
        'your_user_id'
      );
    });

    Promise.all(emailPromises)
      .then((results) => {
        alert('Emails sent successfully!');
      })
      .catch((error) => {
        console.error("Error sending emails:", error);
        alert('Error sending emails');
      });
  };

  return (
    <div className="email-marketing">
      <h2>Email Marketing</h2>
      <div>
        <label htmlFor="userGroup">Grupo de usuarios:</label>
        <select id="userGroup" value={userGroup} onChange={(e) => setUserGroup(e.target.value)}>
          <option value="">Selecciona un grupo</option>
          <option value="all">Todos los usuarios registrados</option>
          <option value="active">Usuarios activos (últimos 7 días)</option>
          <option value="inactive">Usuarios inactivos</option>
        </select>
      </div>

      <div>
        <label htmlFor="subject">Asunto:</label>
        <input
          id="subject"
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>

      <div>
        <label htmlFor="message">Mensaje:</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>

      <button onClick={sendEmail} disabled={loading || !recipients.length}>
        {loading ? 'Cargando usuarios...' : 'Enviar Email'}
      </button>

      <p>Destinatarios seleccionados: {recipients.length}</p>
    </div>
  );
};

export default EmailMarketing;
