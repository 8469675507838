import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext.js';
import lmolis from '../assets/logo-molis384.png';
import lreservos from '../assets/res.png';
import './Menu.css'; 

const Menu = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const { currentUser, userRole, logout } = useAuth(); // Asegúrate de que logout está correctamente exportado desde AuthContext

  const toggleMenu = () => {
    setMenuVisible(prevState => !prevState);
  };

  const goToRentals = () => {
    navigate('/alquileres');
  };

  const goToSales = () => {
    navigate('/ventas');
  };

  const goToOffice = () => {
    if (currentUser) {
      navigate('/oficina');
    } else {
      navigate('/Login');
    }
  };

  const goToAdminPanel = () => {
    if (userRole === 'admin') {
      navigate('/admin');
    }
  };

  // Función para manejar el cierre de sesión y redirigir al HomePage
  const handleLogout = async () => {
    try {
      await logout(); // Cierra la sesión
      navigate('/'); // Redirige a la página principal
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  return (
    <div>
      <div className={`menu-alto ${isMenuVisible ? 'show' : 'hide'}`}>
        <div id="about" className="widget widgetImg">
         <img src={lreservos} alt="Logo de Reservos portal inmobiliario" style={{ width: "150px" }} />
        </div>
        <div id="secciones" className="widget c-logo">
          <ul>
            <h5>Configuración</h5>
            <Link to="/cuenta">
              <li className="item-menu">Mi cuenta</li>
            </Link>
            <hr />
            <h5>Ganá con Reservos</h5>
            <Link to="/comparti-gana">
              <li className="item-menu">Compartí y ganá</li>
            </Link>
            <Link to="/se-parte">
              <li className="item-menu">Únete a nuestro equipo</li>
            </Link>
            <Link to="/sumar-inm">
              <li className="item-menu">Sumar mi inmobiliaria</li>
            </Link>
            <Link to="/idea">
              <li className="item-menu">¡Tengo una idea de negocio!</li>
            </Link>
            <hr />
            <h5>Herramientas</h5>
            <a href="https://apimys.com/">
              <li>Servicios Profesionales</li>
            </a>
            <a href="https://reservos.com/">
              <li>Reservos</li>
            </a>
            <hr />
            <h5>Soporte</h5>
            <Link to="/faq">
              <li className="item-menu">Necesito Ayuda</li>
            </Link>
            <Link to="/politicas-y-privacidad">
              <li className="item-menu">Políticas y Privacidad</li>
            </Link>
            {currentUser && (
              <li className="item-menu" onClick={handleLogout}>
                Cerrar Sesión
              </li>
            )}
          </ul>
        </div>
        <div id="follow" className="widget follow">
          <h5>Seguinos</h5>
          <ul>
            <li><a href="https://www.facebook.com/Reservos/"><i className="fa-brands fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/reservos.prop/"><i className="fa-brands fa-instagram"></i></a></li>
            <li><a href="https://www.youtube.com/channel/UC-XSSPzmfa_40bhM2UOlhug"><i className="fa-brands fa-youtube"></i></a></li>
            <li><a href="https://www.tiktok.com/@reservos"><i className="fa-brands fa-tiktok"></i></a></li>
          </ul>
        </div>
        <div id="logo" className="widget widgetImg">
         <img src={lmolis} alt="Logo de Molis Group" style={{ width: "100px" }} />
         <p>© Molis 2024. Todos los derechos reservados.</p>
        </div>
      </div>

      <div className="menu-bajo menu-regresar">
        <ul>
          <li className="item-menu2 memenu" onClick={goToSales}>
            <i className="fa-solid fa-tags"></i> {/* Icono para Ventas */}
            Ventas
          </li>
          <li className="item-menu2 memenu" onClick={goToRentals}>
            <i className="fa-solid fa-calendar-day"></i> {/* Icono para Alquileres Temporarios */}
            Alquiler
          </li>
          <li className={`item-menu2 ${!currentUser ? 'login' : ''} memenu`} onClick={goToOffice}>
            <i className="fa-solid fa-user-tie"></i> {/* Icono para Oficina */}
            Oficina
          </li>
          {userRole === 'admin' && (
            <li className="item-menu2 memenu" onClick={goToAdminPanel}>
              <i className="fa-solid fa-user-shield"></i> {/* Icono para Admin */}
              Admin.
            </li>
          )}
          <li className="item-menu2 menu-toggle-bajo simenu" onClick={toggleMenu}>
            <i className="fa-solid fa-bars"></i> {/* Icono para abrir/cerrar el menú */}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
