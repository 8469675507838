import React from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const MapComponent = ({ lat, lng }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAMQ2JSPyDNmfDXpenLRDZuMLH1gLfIcn8", 
  });

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };

  const center = {
    lat: lat,
    lng: lng,
  };

  if (!isLoaded) return <div>Cargando mapa...</div>;

  return (
    <GoogleMap mapContainerStyle={mapContainerStyle} zoom={15} center={center}>
      <Marker position={center} />
    </GoogleMap>
  );
};

export default MapComponent;
