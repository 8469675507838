import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig.js';
import PropertyCard from './components/PropertyCard.js';
import Menu from './components/Menu.js';
import Loading from './components/Loading.js';

const InmobiliariaProfile = () => {
  const { inmobiliariaId } = useParams();
  const [inmobiliaria, setInmobiliaria] = useState(null);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    tipo: '',
    barrio: '',
    precioMin: '',
    precioMax: '',
    ambientes: '',
    estado: '',
  });
  const [uniqueOptions, setUniqueOptions] = useState({
    tipos: [],
    barrios: [],
    ambientes: [],
    estados: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 9;

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = properties.slice(indexOfFirstProperty, indexOfLastProperty);

  useEffect(() => {
    const fetchInmobiliaria = async () => {
      try {
        const inmobiliariaRef = doc(db, 'inmobiliarias', inmobiliariaId);
        const inmobiliariaDoc = await getDoc(inmobiliariaRef);
        if (inmobiliariaDoc.exists()) {
          setInmobiliaria(inmobiliariaDoc.data());
        }
      } catch (error) {
        console.error("Error fetching inmobiliaria data:", error);
      }
    };

    const fetchProperties = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'properties'));
        const filteredProperties = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(property => property.inm === inmobiliariaId);

        setProperties(filteredProperties);
        extractUniqueOptions(filteredProperties);
      } catch (error) {
        console.error("Error fetching properties:", error);
      } finally {
        setLoading(false);
      }
    };

    const extractUniqueOptions = (properties) => {
      const tipos = [...new Set(properties.map(prop => prop.tipo))];
      const barrios = [...new Set(properties.map(prop => prop.barrio))];
      const ambientes = [...new Set(properties.map(prop => prop.amb))];
      const estados = [...new Set(properties.map(prop => prop.estado))];

      setUniqueOptions({ tipos, barrios, ambientes, estados });
    };

    fetchInmobiliaria();
    fetchProperties();
  }, [inmobiliariaId]);

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const filteredProperties = currentProperties.filter(property => {
    const { tipo, barrio, precioMin, precioMax, ambientes, estado } = filters;
    return (
      (tipo ? property.tipo === tipo : true) &&
      (barrio ? property.barrio === barrio : true) &&
      (ambientes ? property.amb === Number(ambientes) : true) &&
      (estado ? property.estado === estado : true) &&
      (precioMin ? property.venta >= Number(precioMin) : true) &&
      (precioMax ? property.venta <= Number(precioMax) : true)
    );
  });

  const nextPage = () => {
    if (currentPage < Math.ceil(properties.length / propertiesPerPage)) {
      setCurrentPage(prevPage => prevPage + 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className="vp-container">
      <div className="property-list-container vp-main-content">   
        {loading ? (
          <div id="carga" className="containerLoading">
            <Loading />
          </div>
        ) : (
          <>
            <div className="headerInm">
                <h1>{inmobiliaria?.nombre}</h1>
                <p>Registro: {inmobiliaria?.registro}</p>
                <p>Descripción: {inmobiliaria?.descripcion}</p>
            </div>
            <div className="filter-form">
              <h2>Filtrar Propiedades</h2>
              <form>
                <div className="form-group">
                  <label>Tipo:</label>
                  <select name="tipo" className="form-control" onChange={handleFilterChange}>
                    <option value="">Todos</option>
                    {uniqueOptions.tipos.map(tipo => (
                      <option key={tipo} value={tipo}>{tipo}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Barrio:</label>
                  <select name="barrio" className="form-control" onChange={handleFilterChange}>
                    <option value="">Todos</option>
                    {uniqueOptions.barrios.map(barrio => (
                      <option key={barrio} value={barrio}>{barrio}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Precio Mínimo:</label>
                  <input type="number" name="precioMin" className="form-control" onChange={handleFilterChange} />
                </div>
                <div className="form-group">
                  <label>Precio Máximo:</label>
                  <input type="number" name="precioMax" className="form-control" onChange={handleFilterChange} />
                </div>
                <div className="form-group">
                  <label>Ambientes:</label>
                  <select name="ambientes" className="form-control" onChange={handleFilterChange}>
                    <option value="">Todos</option>
                    {uniqueOptions.ambientes.map(amb => (
                      <option key={amb} value={amb}>{amb}</option>
                    ))}
                  </select>
                </div>
              </form>
            </div>

            <div className="properties-list">
              <h2>Propiedades</h2>
              <div className="properties-grid">
                {filteredProperties.length > 0 ? (
                  filteredProperties.map(property => (
                    <PropertyCard key={property.id} property={property} archivos={property.archivos || []} />
                  ))
                ) : (
                  <p>No hay propiedades que coincidan con el filtro.</p>
                )}
              </div>
              <div className="pagination">
                {currentPage > 1 && (
                  <button onClick={prevPage}>
                    Anterior
                  </button>
                )}
                <button onClick={nextPage} disabled={currentPage === Math.ceil(properties.length / propertiesPerPage)}>
                  Siguiente
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <Menu />
    </div>
  );
};

export default InmobiliariaProfile;
