import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { useAuth } from '../context/AuthContext.js';
import './PropertyFilter.css';
import PropertyCard from './PropertyCard.js';

const PropertyFilter = () => {
  const { currentUser } = useAuth();
  const [query, setQuery] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [barrios, setBarrios] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 9;

  // Nuevos estados para los filtros
  const [tipoPropiedad, setTipoPropiedad] = useState('');
  const [cantidadAmbientes, setCantidadAmbientes] = useState('');
  const [cantidadBanios, setCantidadBanios] = useState('');
  const [cantidadDormitorios, setCantidadDormitorios] = useState('');
  const [presupuestoMinimo, setPresupuestoMinimo] = useState('');
  const [presupuestoMaximo, setPresupuestoMaximo] = useState('');
  const [zonas, setZonas] = useState('');
  const [estado, setEstado] = useState('');
  const [searchCount, setSearchCount] = useState(0);
  
  // Estado para el textarea
  const [observaciones, setObservaciones] = useState('');

  // Nuevos estados para checkboxes
  const [filters, setFilters] = useState({
    financia: false,
    baulera: false,
    cochera: false,
    credito: false,
    dep: false,
    permuta: false,
    balcon: false,
    quincho: false,
    piscina: false,
    amoblado: false,
  });

  useEffect(() => {
    const fetchProperties = async () => {
      const propertiesSnapshot = await getDocs(collection(db, 'properties'));
      const propertiesList = propertiesSnapshot.docs.map((doc) => doc.data());

      const uniqueBarrios = [...new Set(propertiesList.map((prop) => prop.barrio?.toLowerCase()))];
      const uniqueCiudades = [...new Set(propertiesList.map((prop) => prop.ciudad?.toLowerCase()))];

      setBarrios(uniqueBarrios);
      setCiudades(uniqueCiudades);
    };

    fetchProperties();
  }, []);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const processQuery = async () => {
    setLoading(true);
    setSearchInitiated(true);

    const propertiesSnapshot = await getDocs(collection(db, 'properties'));
    const propertiesList = propertiesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const results = propertiesList.filter((property) => filterProperties(property));

    setFilteredProperties(results);
    setCurrentPage(1);
    setLoading(false);
  };

  const filterProperties = (property) => {
    let matchFound = true;

    // Filtro por tipo de propiedad
    if (tipoPropiedad && property.tipo !== tipoPropiedad) matchFound = false;

     // Filtro por cantidad de ambientes
     if (cantidadAmbientes && Number(property.amb) !== Number(cantidadAmbientes)) matchFound = false;

     // Filtro por cantidad de dormitorios
     if (cantidadDormitorios && Number(property.dormitorios) !== Number(cantidadDormitorios)) matchFound = false;
 
     // Filtro por cantidad de baños
     if (cantidadBanios && Number(property.banos) !== Number(cantidadBanios)) matchFound = false;
 
    // Filtro por presupuesto
    if (presupuestoMinimo && property.publicacion < Number(presupuestoMinimo)) matchFound = false;
    if (presupuestoMaximo && property.publicacion > Number(presupuestoMaximo)) matchFound = false;

    // Filtro por zonas
    const zonasArray = zonas ? zonas.split(',').map(zona => zona.trim().toLowerCase()) : [];
    if (zonasArray.length > 0 && !zonasArray.includes(property.barrio?.toLowerCase())) matchFound = false;


    // Filtro por estado
    if (estado && property.estado !== estado) matchFound = false;

    // Filtro por características booleanas
    for (const [key, value] of Object.entries(filters)) {
      if (value && !property[key]) matchFound = false;
    }

    // Filtro por operación "venta" y que el campo "venta" esté vacío, no exista o sea null
    if (property.operacion !== "venta" || property.venta) matchFound = false;


    return matchFound;
  };

  // Lógica de paginación
  const totalProperties = filteredProperties.length;
  const totalPages = Math.ceil(totalProperties / propertiesPerPage);
  const startIndex = (currentPage - 1) * propertiesPerPage;
  const currentProperties = filteredProperties.slice(startIndex, startIndex + propertiesPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters({ ...filters, [name]: checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (searchCount >= 20) {
      return;
    }

    try {
      await addDoc(collection(db, 'busquedas'), {
        uid: currentUser.uid,
        query,
        tipoPropiedad,
        cantidadAmbientes,
        cantidadBanios,
        cantidadDormitorios,
        presupuestoMinimo,
        presupuestoMaximo,
        zonas,
        estado,
        observaciones, // Guardar el textarea
        filters, // Guardar los checkboxes
        createdAt: new Date(),
      });
      setSearchCount(prevCount => prevCount + 1);
      processQuery();
    } catch (error) {
      console.error('Error al guardar la búsqueda: ', error.message);
    }
  };

  return (
    <div className="property-filter">
      <form onSubmit={handleSubmit}>
        <h2>Buscá tu lugar, viví tu historia.</h2>
        <div className="input-group">
          <select value={tipoPropiedad} onChange={(e) => setTipoPropiedad(e.target.value)} className="form-control">
            <option value="">Selecciona un tipo</option>
            <option value="Campo">Campo</option>
            <option value="Casa">Casa</option>
            <option value="Cochera">Cochera</option>
            <option value="Departamento">Departamento</option>
            <option value="Duplex">Duplex</option>
            <option value="En pozo">En pozo</option>
            <option value="Galpon">Galpón</option>
            <option value="Hotel">Hotel</option>
            <option value="Local">Local</option>
            <option value="Lote">Lote</option>
            <option value="Oficina">Oficina</option>
            <option value="Ph">PH</option>
          </select>
          <input
            type="number"
            placeholder="Cantidad de ambientes"
            value={cantidadAmbientes}
            onChange={(e) => setCantidadAmbientes(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="input-group">
          <input
            type="number"
            placeholder="Cantidad de dormitorios"
            value={cantidadDormitorios}
            onChange={(e) => setCantidadDormitorios(e.target.value)}
            className="form-control"
          />
          <input
            type="number"
            placeholder="Cantidad de baños"
            value={cantidadBanios}
            onChange={(e) => setCantidadBanios(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="input-group">
          <input
            type="number"
            placeholder="Presupuesto mínimo"
            value={presupuestoMinimo}
            onChange={(e) => setPresupuestoMinimo(e.target.value)}
            className="form-control"
          />
          <input
            type="number"
            placeholder="Presupuesto máximo"
            value={presupuestoMaximo}
            onChange={(e) => setPresupuestoMaximo(e.target.value)}
            className="form-control"
          />
        </div>
        
        <div className="input-group">
          <input
            type="text"
            placeholder="Zonas (separadas por coma)"
            value={zonas}
            onChange={(e) => setZonas(e.target.value)}
            className="form-control"
          />
          <select value={estado} onChange={(e) => setEstado(e.target.value)} className="form-control">
            <option value="">Selecciona un estado</option>
            <option value="A reciclar">A reciclar</option>
            <option value="Bueno">Bueno</option>
            <option value="Muy bueno">Muy bueno</option>
            <option value="Excelente">Excelente</option>
            <option value="A estrenar">A estrenar</option>
            <option value="Reciclado">Reciclado</option>
            <option value="Al pozo">Al pozo</option>
          </select>
        </div>

        {/* Checkboxes para características */}
        <div className="checkbox-group">
          {Object.keys(filters).map((filterName) => (
            <div key={filterName} className="form-check">
              <input
                type="checkbox"
                name={filterName}
                checked={filters[filterName]}
                onChange={handleCheckboxChange}
                className="form-check-input"
              />
              <label className="form-check-label">{filterName}</label>
            </div>
          ))}
        </div>

        {/* Textarea para observaciones */}
        
          <label>Queremos ofrecerte la mejor experiencia, contanos en detalles lo que buscás</label>
          <textarea
            placeholder="Ej. Buscamos un departamento con vista al mar para inversión y obtener buena renta."
            value={observaciones}
            onChange={(e) => setObservaciones(e.target.value)}
            className="form-control filter-textarea"
          />
        

        <button type="submit" className="btn btn-primary">Buscar</button>
      </form>

      {/* Resultados */}

      <div className="properties-list">
          {loading ? (
            <p>Cargando propiedades...</p>
          ) : (
            currentProperties.length > 0 ? (
              <>
                {currentProperties.map((property) => (
                  <PropertyCard key={property.id} property={property} archivos={property.archivos || []} />
                ))}
        
                {/* Paginación */}
                <div className="pagination">
                  <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Anterior
                  </button>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Siguiente
                  </button>
                </div>
              </>
            ) : (
              <p>No se encontraron propiedades con los filtros seleccionados.</p>
            )
          )}
        </div>
    
      
    </div>
  );
};

export default PropertyFilter;
