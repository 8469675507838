import React, { useEffect, useState } from 'react';
import { useAuth } from './context/AuthContext.js'; // Usar el contexto de autenticación
import { useNavigate } from 'react-router-dom';
import AdminProperties from './components/AdminProperties.js';
import AddProperty from './components/AddProperty.js';
import CrearInm from './components/CrearInm.js';
import InmobiliariasList from './components/InmobiliariasList.js';
import AdminUsers from './components/AdminUsers.js';
import EmailMarketing from './components/EmailMarketing.js';
import SearchAnalytics from './components/SearchAnalytics.js'; // Componente para análisis de búsquedas
import './AdminPanel.css';

const AdminPanel = () => {
  const { currentUser, userRole } = useAuth();
  const [view, setView] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login'); // Redirige si no hay usuario autenticado
    } else if (userRole !== 'admin') {
      navigate('/'); // Redirige si el usuario no es admin
    }
  }, [currentUser, userRole, navigate]);

  // Muestra un indicador de carga mientras se verifica el rol
  if (!currentUser || userRole === null) {
    return <p>Cargando...</p>;
  }

  const handleViewChange = (viewName) => {
    setView(viewName);
  };

  return (
    <div className="admin-panel">
      <h2>Panel de Administración</h2>
      <div className="admin-menu">
        <button onClick={() => handleViewChange('users')} className="menu-item">
          <i className="fas fa-users"></i> Usuarios
        </button>
        <button onClick={() => handleViewChange('properties')} className="menu-item">
          <i className="fas fa-home"></i> Propiedades
        </button>
        <button onClick={() => handleViewChange('inmobiliarias')} className="menu-item">
          <i className="fas fa-building"></i> Inmobiliarias
        </button>
        <button onClick={() => handleViewChange('search-analytics')} className="menu-item">
          <i className="fas fa-search"></i> Búsquedas
        </button>
        <button onClick={() => handleViewChange('email-marketing')} className="menu-item">
          <i className="fas fa-envelope"></i> Email Marketing
        </button>
      </div>
      
      {/* Renderizado condicional de cada vista seleccionada */}
      {view === 'properties' && (
        <div className="submenu">
          <button onClick={() => handleViewChange('properties-list')} className="submenu-item">
            Ver Propiedades
          </button>
          <button onClick={() => handleViewChange('add-property')} className="submenu-item">
            Cargar Nueva Propiedad
          </button>
        </div>
      )}
      {view === 'properties-list' && <AdminProperties />}
      {view === 'add-property' && <AddProperty />}
      {view === 'inmobiliarias' && (
        <div>
          <InmobiliariasList />
          <CrearInm />
        </div>
      )}
      {view === 'users' && <AdminUsers />}
      {view === 'email-marketing' && <EmailMarketing />}
      {view === 'search-analytics' && <SearchAnalytics />}
    </div>
  );
};

export default AdminPanel;
