import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from './firebaseConfig.js'; // Importa tu configuración de Firebase

const Document = () => {
  const { docId } = useParams();
  const [documentData, setDocumentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [dni, setDni] = useState('');
  const canvasRef = useRef(null);
  const [signatures, setSignatures] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const docRef = doc(db, 'documents', docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setDocumentData(docSnap.data());
          setSignatures(docSnap.data().signatures || []);
        } else {
          setError('El documento no existe');
        }
      } catch (err) {
        setError('Error al cargar el documento');
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [docId]);

  const formatText = (text) => {
    if (!text) return '';
    const boldPattern = /\*\*(.*?)\*\*/g;
    const underlinePattern = /--(.*?)--/g;
    let formattedText = text.replace(boldPattern, '<strong>$1</strong>');
    formattedText = formattedText.replace(underlinePattern, '<u>$1</u>');
    const bothPattern = /\*\*--(.*?)--\*\*/g;
    formattedText = formattedText.replace(bothPattern, '<strong><u>$1</u></strong>');
    return formattedText;
  };

  const startDrawing = (x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(x, y);
    setIsDrawing(true);
  };

  const draw = (x, y) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const handleMouseDown = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    startDrawing(offsetX, offsetY);
  };

  const handleMouseMove = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    draw(offsetX, offsetY);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    const rect = canvasRef.current.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    startDrawing(x, y);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    const rect = canvasRef.current.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    draw(x, y);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  const handleSubmitSignature = async () => {
    const canvas = canvasRef.current;
    const signatureImage = canvas.toDataURL('image/png');

    const newSignature = {
      name,
      dni,
      signatureImage,
      timestamp: new Date().toISOString(),
    };

    try {
      const docRef = doc(db, 'documents', docId);
      await updateDoc(docRef, {
        signatures: arrayUnion(newSignature),
      });

      setSignatures([...signatures, newSignature]);
      setShowModal(false);
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setName('');
      setDni('');
    } catch (err) {
      console.error('Error guardando la firma:', err);
    }
  };

  if (loading) {
    return <div>Cargando el documento...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="conteainerDocument">
      <h1>{documentData?.title || 'Sin título'}</h1>
      <p
        className="textContentDoc"
        dangerouslySetInnerHTML={{
          __html: formatText(documentData?.text || 'El documento no tiene contenido.'),
        }}
      ></p>

      {/* Mostrar las firmas actuales */}
      <div>
        <h3>Firmas</h3>
        <div className="containerFirmas">
          {signatures.length > 0 ? (
            signatures.map((signature, index) => (
              <div key={index} style={{ margin: '5px' }}>
                <p>Nombre: {signature.name}</p>
                <p>DNI: {signature.dni}</p>
                <img
                  src={signature.signatureImage}
                  alt={`Firma de ${signature.name}`}
                  className="imgCanvaFirma"
                />
                <p>Fecha: {new Date(signature.timestamp).toLocaleString()}</p>
              </div>
            ))
          ) : (
            <p>No hay firmas todavía.</p>
          )}
        </div>
      </div>

      <button onClick={() => setShowModal(true)}>Firmar Documento</button>

      {/* Modal personalizado */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Firma el documento</h2>
            <form>
              <div className="form-group">
                <label>Nombre Completo</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>DNI</label>
                <input
                  type="text"
                  value={dni}
                  onChange={(e) => setDni(e.target.value)}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Firma</label>
                <canvas
                  ref={canvasRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={stopDrawing}
                  onMouseLeave={stopDrawing}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={stopDrawing}
                  height={200}
                  className="form-canvas"
                />
                <button
                  type="button"
                  onClick={() => {
                    const canvas = canvasRef.current;
                    const ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                  }}
                >
                  Limpiar Firma
                </button>
              </div>
            </form>
            <button onClick={handleSubmitSignature}>Guardar Firma</button>
            <button onClick={() => setShowModal(false)}>Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Document;
