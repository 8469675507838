import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig.js'; // Importa la configuración de Firebase
import { collection, getDocs, query, where, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import './AdminUsers.css';

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollection = collection(db, 'users');
        const userDocs = await getDocs(usersCollection);
        const usersData = await Promise.all(userDocs.docs.map(async (doc) => {
          const userId = doc.id;
          const userData = doc.data();

          // Obtener la cantidad de referidos
          const referredUsersQuery = query(usersCollection, where('referido', '==', userId));
          const referredUsersSnapshot = await getDocs(referredUsersQuery);
          const referredCount = referredUsersSnapshot.size;

          // Obtener la cantidad de consultas del usuario
          const searchesCollection = collection(db, 'busquedas');
          const userSearchesQuery = query(searchesCollection, where('uid', '==', userId));
          const userSearchesSnapshot = await getDocs(userSearchesQuery);
          const searchCount = userSearchesSnapshot.size;

          return {
            id: userId,
            fullName: userData.fullName || 'N/A',
            email: userData.email || 'N/A',
            role: userData.role || 'N/A',
            referredCount,
            searchCount,
          };
        }));

        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener usuarios:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return <p>Cargando usuarios...</p>;
  }

  // Función para ver las consultas de un usuario en un modal o componente separado
  const viewUserSearches = (userId) => {
    console.log(`Ver consultas para usuario con ID: ${userId}`);
  };

  // Función para bloquear un usuario
  const blockUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { bloqueado: true });
      alert('Usuario bloqueado exitosamente');
    } catch (error) {
      console.error("Error al bloquear el usuario:", error);
      alert('Error al bloquear el usuario');
    }
  };

  // Función para eliminar un usuario
  const deleteUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      await deleteDoc(userRef);

      // Eliminar las búsquedas asociadas al usuario
      const searchesCollection = collection(db, 'busquedas');
      const userSearchesQuery = query(searchesCollection, where('uid', '==', userId));
      const userSearchesSnapshot = await getDocs(userSearchesQuery);
      const deleteSearchesPromises = userSearchesSnapshot.docs.map((searchDoc) =>
        deleteDoc(searchDoc.ref)
      );
      await Promise.all(deleteSearchesPromises);

      alert('Usuario y consultas eliminados exitosamente');
      // Actualizar la lista de usuarios tras eliminación
      setUsers(users.filter((user) => user.id !== userId));
    } catch (error) {
      console.error("Error al eliminar el usuario:", error);
      alert('Error al eliminar el usuario');
    }
  };

  return (
    <div className="admin-users">
      <div className="user-count">
        <p>{users.length} usuarios.</p>
      </div>
      <h3>Gestión de Usuarios</h3>
      <table className="users-table">
        <thead>
          <tr>
            <th>Nombre Completo</th>
            <th>Email</th>
            <th>Rol</th>
            <th>Referidos</th>
            <th>Consultas</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.fullName}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>{user.referredCount}</td>
              <td>{user.searchCount}</td>
              <td>
                <button onClick={() => viewUserSearches(user.id)} className="btnVer">Ver Consultas</button>
                <button onClick={() => blockUser(user.id)} className="btnBloquear">Bloquear</button>
                <button onClick={() => deleteUser(user.id)} className="btnEliminar">Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminUsers;
