import React, { useEffect, useState } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import './AdminProperties.css'; // Importar el archivo CSS
import EditeProperty from './EditeProperty.js';
import Inventario from './Inventario.js'; // Asegúrate de que este componente esté creado

const Pagination = ({ propertiesPerPage, totalProperties, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProperties / propertiesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className='pagination'>
        {currentPage > 1 && (
          <li className='page-item'>
            <a onClick={() => paginate(currentPage - 1)} className='page-link'>
              Anterior
            </a>
          </li>
        )}

        {currentPage < pageNumbers.length && (
          <li className='page-item'>
            <a onClick={() => paginate(currentPage + 1)} className='page-link'>
              Siguiente
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

const AdminProperties = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 10;

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bajaReason, setBajaReason] = useState('');
  const [salePrice, setSalePrice] = useState('');

  const [showInventarioModal, setShowInventarioModal] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const propertiesCollection = collection(db, 'properties');
        const propertiesSnapshot = await getDocs(propertiesCollection);
        const propertiesList = propertiesSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(property => !property.venta || property.venta === null || property.venta === ''); // Filtra propiedades no vendidas ni retiradas
        setProperties(propertiesList);
        setFilteredProperties(propertiesList);
      } catch (error) {
        console.error('Error fetching properties:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    const lowercasedSearchTerm = searchTerm.toLowerCase(); // Convierte el término de búsqueda a minúsculas
    setFilteredProperties(
      properties.filter(property => 
        property.direccion?.toLowerCase().includes(lowercasedSearchTerm) || // Filtra por dirección
        property.barrio?.toLowerCase().includes(lowercasedSearchTerm) // Filtra por barrio
      )
    );
  }, [searchTerm, properties]);
  
  

  const handleDelete = (property) => {
    setSelectedProperty(property);
    setShowDeleteModal(true);
  };

  const handleBajaSubmit = async () => {
    try {
      const updatedPrice = bajaReason === 'vendido' ? salePrice : '0';
      await updateDoc(doc(db, 'properties', selectedProperty.id), {
        venta: updatedPrice,
        motivo: bajaReason
      });
      setProperties(properties.filter(prop => prop.id !== selectedProperty.id));
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error updating property:', error);
    }
  };

  const handleEdit = (property) => {
    setSelectedProperty(property);
    setShowEditModal(true);
  };

  const handleInventario = (property) => {
    setSelectedProperty(property);
    setShowInventarioModal(true);
  };

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return <p>Cargando propiedades...</p>;
  }

  return (
    <div>
      <h3>Lista de Propiedades</h3>

      <input 
        type="text" 
        className="inputMolis" 
        placeholder="Buscar propiedades..." 
        value={searchTerm} 
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <table>
        <thead>
          <tr>
            <th>Imagen</th>
            <th>Dirección</th>
            <th>Publicación</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentProperties.map(property => (
            <tr key={property.id}>
              <td>
                {property.archivos && property.archivos.length > 0 ? (
                  <img src={property.archivos[0].fileURL} alt="Imagen de la propiedad" className="thumbnail" />
                ) : (
                  <span>No image available</span>
                )}
              </td>
              <td>{property.direccion}</td>
              <td>{property.publicacion}</td>
              <td>
                <button onClick={() => handleEdit(property)} className="btnVer">Editar</button>
                <button onClick={() => handleDelete(property)} className="btnEliminar">Eliminar</button>
                {property.operacion === 'alquiler temporario' && (
                  <button onClick={() => handleInventario(property)} className="btnBloquear">Inventario</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination 
        propertiesPerPage={propertiesPerPage} 
        totalProperties={filteredProperties.length} 
        paginate={paginate} 
        currentPage={currentPage}
      />

      {showEditModal && (
        <EditeProperty
          property={selectedProperty} 
          closeModal={() => setShowEditModal(false)} 
          onSave={(updatedProperty) => {
            setProperties(properties.map(prop =>
              prop.id === updatedProperty.id ? updatedProperty : prop
            ));
            setShowEditModal(false);
          }}
        />
      )}

      {showDeleteModal && (
        <div className="modal">
          <div className="container-edite-form">
            <h2>Eliminar Propiedad</h2>
            <div>
              <label>¿Cuál es el motivo de la eliminación?</label>
              <input type="text" value={bajaReason} onChange={(e) => setBajaReason(e.target.value)} className="form-control" />
            </div>
            <button onClick={handleBajaSubmit}>Eliminar</button>
            <button onClick={() => setShowDeleteModal(false)}>Cancelar</button>
          </div>
        </div>
      )}

      {showInventarioModal && (
        <Inventario
          property={selectedProperty}
          closeModal={() => setShowInventarioModal(false)}
        />
      )}
    </div>
  );
};

export default AdminProperties;
