// src/components/RoleSelection.js
import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig.js'; // Asegúrate de la ruta correcta
import { doc, setDoc, collection, addDoc, getDocs } from 'firebase/firestore'; // Asegúrate de importar getDocs

const RoleSelection = ({ onSelectRole }) => {
  const [inmobiliaria, setInmobiliaria] = useState('');
  const [inmobiliarias, setInmobiliarias] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchInmobiliarias = async () => {
      try {
        const inmobiliariasCollection = collection(db, 'inmobiliarias');
        const inmobiliariasSnapshot = await getDocs(inmobiliariasCollection);
        const inmobiliariasList = inmobiliariasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInmobiliarias(inmobiliariasList);
      } catch (error) {
        console.error("Error al obtener las inmobiliarias: ", error);
        setError("No se pudieron cargar las inmobiliarias.");
      }
    };

    fetchInmobiliarias();
  }, []);

  const handleConfirm = async () => {
    const user = auth.currentUser;
    if (user && inmobiliaria) {
      try {
        // Crear una solicitud para el propietario de la inmobiliaria
        const solicitudRef = collection(db, 'solicitudesAs');
        await addDoc(solicitudRef, {
          userId: user.uid,
          inmobiliaria,
          status: 'pending'
        });

        // Actualizar la inmobiliaria en Firestore
        await setDoc(doc(db, 'users', user.uid), { inmobiliaria }, { merge: true });
        setIsConfirmed(true);
        setInmobiliaria(''); // Limpiar selección
        onSelectRole('asesor'); // Cambiamos el rol a asesor después de la confirmación
      } catch (error) {
        console.error("Error al enviar la solicitud: ", error);
        setError("No se pudo enviar la solicitud. Intenta de nuevo.");
      }
    }
  };

  return (
    <div className="role-selection">
      <h3>Selecciona el tipo de oficina que queres</h3>
      <button onClick={() => onSelectRole('propietario')} className="btn moli-o">Soy Propietario</button>

      {/* Selección de inmobiliaria si no está confirmada */}
      {!isConfirmed && (
        <div className="add-property">
          <p>Si sos asesor inmobiliario indica la inmobiliaria con la que trabajas</p>
          <select className="form-control" onChange={(e) => setInmobiliaria(e.target.value)} value={inmobiliaria}>
            <option value="">Selecciona una inmobiliaria</option>
            {inmobiliarias.map((inmo) => (
              <option key={inmo.id} value={inmo.id}>{inmo.nombre}</option>
            ))}
          </select>
          <button onClick={handleConfirm} disabled={!inmobiliaria}>Confirmar</button>
        </div>
      )}

      {/* Mensaje de espera si aún no ha sido confirmado */}
      {isConfirmed === false && (
        <p className="mensajeEspera">Espera a que se apruebe tu ingreso como asesor de la inmobiliaria que seleccionaste.</p>
      )}

      {/* Mensaje de error si existe */}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default RoleSelection;
