// src/components/OfficeAsesor.js
import React, { useState, useEffect } from 'react';
import { collection, doc, query, where, getDocs, updateDoc, getDoc, addDoc, setDoc } from 'firebase/firestore'; // Importar setDoc
import { db } from '../firebaseConfig.js';
import AddProperty from './AddProperty.js'; 
import { useAuth } from '../context/AuthContext.js';
import AddClient from './AddClient.js';
import ClientTable from './ClientTable.js';
import CreateDocument from './DigitalDocumentCreator.js';
import './Office.css';

const OfficeAsesor = () => {
  const [clients, setClients] = useState([]);
  const [properties, setProperties] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchUserRole = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setIsConfirmed(!!userDoc.data().inmobiliaria);
        }
      }
    };

    fetchUserRole();
  }, [currentUser]);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
  };

  return (
    <div className="office-container">
      {isConfirmed && (
        <>
          <div className="section-container">
            <div className="section" onClick={() => handleSectionClick('clientes')}>
              <h3>Prospectos</h3>
            </div>
            <div className="section" onClick={() => handleSectionClick('propiedades')}>
              <h3>Prop.</h3>
            </div>
            <div className="section" onClick={() => handleSectionClick('documentos')}>
              <h3>Doc.</h3>
            </div>
          </div>

          {/* Sección de Clientes */}
          {activeSection === 'clientes' && (
            <>
              <button className="btn btn-primary" onClick={() => setShowForm(false)}>
                Ver Prospectos
              </button>
              <button className="btn btn-secondary" onClick={() => setShowForm(!showForm)}>
                Agregar Nuevo Prospecto
              </button>

              {showForm && <AddClient />}
              {!showForm && <ClientTable />}
            </>
          )}

          {/* Sección de Propiedades */}
          {activeSection === 'propiedades' && (
            <>
              <div className="sectionPropOfice">
                <h2>Propiedades Cargadas</h2>
                {properties.length > 0 ? (
                  properties.map((property, index) => (
                    <div key={index}>
                      <h3>{property.direccion}</h3>
                    </div>
                  ))
                ) : (
                  <p>No has cargado propiedades.</p>
                )}
                <button onClick={toggleForm}>
                  {showForm ? 'Ocultar formulario' : 'Cargar nueva propiedad'}
                </button>
                {showForm && (
                  <AddProperty
                    userId={currentUser ? currentUser.uid : null}
                  />
                )}
              </div>
            </>
          )}

          {/* Sección de Documentos */}
          {activeSection === 'documentos' && (
            <>
              <CreateDocument />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OfficeAsesor;
