import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import { useAuth } from '../context/AuthContext.js';
import jsPDF from 'jspdf';

const DocumentCreator = () => {
  const { currentUser } = useAuth();
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [documents, setDocuments] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [expandedDoc, setExpandedDoc] = useState(null);
  const [showFullText, setShowFullText] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const fetchClients = async () => {
    if (currentUser) {
      const clientCollection = collection(db, 'clientes');
      const q = query(clientCollection, where('creadoPor', '==', currentUser.uid));
      const clientSnapshot = await getDocs(q);
      const clientList = clientSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientList);
    }
  };

  useEffect(() => {
    fetchClients();
  }, [currentUser]);

  const formatText = (text) => {
    if (!text) return null;
    return text.split('\n').map((line, index) => {
      let formattedLine = line
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/--(.*?)--/g, '<u>$1</u>')
        .replace(/\*\*--(.*?)--\*\*/g, '<u><strong>$1</strong></u>');
      return <p key={index} dangerouslySetInnerHTML={{ __html: formattedLine }}></p>;
    });
  };

  const handleCreateDocument = async () => {
    if (title && text) {
      try {
        await addDoc(collection(db, 'documents'), {
          title,
          text,
          createdBy: currentUser.uid,
          createdAt: new Date(),
          updatedAt: new Date(),
          signed: false,
          sharedWith: [],
          signature: [],
        });
        setTitle('');
        setText('');
        fetchDocuments();
      } catch (error) {
        console.error('Error al crear documento:', error);
      }
    } else {
      alert('Por favor, completa todos los campos.');
    }
  };

  const fetchDocuments = async () => {
    if (currentUser) {
      const docCollection = collection(db, 'documents');
      const q = query(docCollection, where('createdBy', '==', currentUser.uid));
      const docSnapshot = await getDocs(q);
      const docList = docSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDocuments(docList);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setFilteredClients([]);
    } else {
      const filtered = clients.filter(client =>
        client.nombre.toLowerCase().includes(e.target.value.toLowerCase())
      ).slice(0, 8);
      setFilteredClients(filtered);
    }
  };

  const handleClientSelection = (client) => {
    setSelectedClient(client.nombre);
    setSearchTerm(client.nombre);
    setFilteredClients([]);
  };

  const handleSendDocument = async (docId) => {
    const selectedClientInfo = clients.find(client => client.nombre === selectedClient);
    if (!selectedClientInfo) {
      alert('Por favor selecciona un cliente válido');
      return;
    }
  
    const { phone, email } = selectedClientInfo;

    if (!phone && !email) {
      alert('El cliente no tiene teléfono ni correo electrónico. No se puede enviar el documento.');
      return;
    }

    const docLink = `https://reservos.com/document/${docId}`;

    setShowShareOptions(true); // Mostrar opciones de compartir
  };

  const shareViaWhatsApp = (phone, docLink) => {
    const message = `Hola, te comparto el documento: ${docLink}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
    
    window.open(whatsappURL, '_blank');
  };
  
  const shareViaEmail = (email, docLink) => {
    const subject = encodeURIComponent("Te comparto un documento");
    const body = encodeURIComponent(`Hola, te comparto el documento. Puedes verlo en el siguiente enlace: ${docLink}`);
    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
    
    window.open(mailtoLink, '_self');
  };

  const generatePDF = (doc) => {
    const pdf = new jsPDF();
  
    // Encabezado
    pdf.setFontSize(13);
    pdf.setFont('helvetica', 'bold');
    pdf.text(doc.title, pdf.internal.pageSize.width / 2, 10, { align: 'center' });
    pdf.setLineWidth(0.5);
    pdf.line(20, 12, pdf.internal.pageSize.width - 20, 12);
  
    // Texto del documento
    pdf.setFontSize(11);
    pdf.setFont('helvetica', 'normal');
    const textLines = pdf.splitTextToSize(doc.text, 180);
    
    let yPosition = 20;
    
    // Añadir el texto del documento
    textLines.forEach(line => {
      pdf.text(line, 10, yPosition, { align: 'justify' });
      yPosition += 5;
    });
  
    // Espacio para las firmas
    if (doc.signatures && doc.signatures.length > 0) {
      yPosition += 10;  // Añadir espacio antes de las firmas
      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'bold');
      pdf.text('Firmas:', 10, yPosition);
  
      yPosition += 10;
  
      // Configuración para que las firmas estén en línea
      let xPosition = 10;
      const signatureHeight = 25;  // Altura de cada firma en el PDF
      const signatureWidth = 50;  // Ancho de cada firma en el PDF
      const maxXPosition = pdf.internal.pageSize.width - 10;  // Máxima posición x
  
      // Dibujar las firmas
      doc.signatures.forEach((signature, index) => {
        if (xPosition + signatureWidth > maxXPosition) {
          // Si la próxima firma excede el ancho de la página, movemos a la siguiente línea
          xPosition = 10;
          yPosition += signatureHeight + 10;
        }
  
        // Añadir la firma (image base64) al PDF
        pdf.addImage(signature.signatureImage, 'PNG', xPosition, yPosition, signatureWidth, signatureHeight);
        pdf.setFontSize(10);
        pdf.text(`${signature.name} (DNI: ${signature.dni})`, xPosition, yPosition + signatureHeight + 5);
  
        // Mover a la siguiente posición horizontal
        xPosition += signatureWidth + 10;
      });
    }
  
    // Guardar el PDF
    pdf.save(`${doc.title}.pdf`);
  };
  
  
  const toggleDocument = (id) => {
    setExpandedDoc(expandedDoc === id ? null : id);
    setShowFullText(false);
  };

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className="document-creator">
      <h2>Crear Documento</h2>
      <input
        type="text"
        className="form-control"
        placeholder="Título"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        placeholder="Texto del documento..."
        value={text}
        className="form-control"
        onChange={(e) => setText(e.target.value)}
      />
      <button onClick={handleCreateDocument}>Crear Documento</button>

      <h3>Documentos</h3>
      <ul className="listaDocumentos">
        {documents.map(doc => (
          <li key={doc.id} className="liDoc">
            <h4 onClick={() => toggleDocument(doc.id)}>
              {doc.title} - {new Date(doc.createdAt.seconds * 1000).toLocaleDateString()}
            </h4>
            {expandedDoc === doc.id && (
              <>
                <div className="liExp">
                  <div>
                    {formatText(showFullText ? doc.text : `${doc.text.slice(0, 350)}...`)}
                    {doc.text.length > 350 && (
                      <span onClick={toggleFullText} style={{ color: 'blue', cursor: 'pointer' }}>
                        {showFullText ? ' Ver menos' : ' Ver más'}
                      </span>
                    )}
                  </div>
                  <button onClick={() => generatePDF(doc)}>Exportar PDF</button>

                  <input
                    type="text"
                    className="search-input-mis-prop"
                    placeholder="Buscar cliente"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  {filteredClients.length > 0 && (
                    <ul className="liClientesSelected">
                      {filteredClients.map(client => (
                        <li key={client.id} onClick={() => handleClientSelection(client)}>
                          {client.nombre}
                        </li>
                      ))}
                    </ul>
                  )}

                  <button onClick={() => handleSendDocument(doc.id)}>Enviar Documento</button>

                  {showShareOptions && (
                    <div>
                      <button onClick={() => {
                        const selectedClientInfo = clients.find(client => client.nombre === selectedClient);
                        shareViaWhatsApp(selectedClientInfo.phone, `https://reservos.com/document/${doc.id}`);
                        setShowShareOptions(false);
                      }}>
                        Compartir por WhatsApp
                      </button>
                      <button onClick={() => {
                        const selectedClientInfo = clients.find(client => client.nombre === selectedClient);
                        shareViaEmail(selectedClientInfo.email, `https://reservos.com/document/${doc.id}`);
                        setShowShareOptions(false);
                      }}>
                        Compartir por Email
                      </button>
                    </div>
                  )}

                  <button onClick={() => setExpandedDoc(null)} className="close-button">X</button>
                </div>
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentCreator;
