import React, { useState } from 'react';
import { db } from '../firebaseConfig.js';
import { doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext.js';

const EditClient = ({ client, onClientUpdated }) => {
  const { currentUser } = useAuth();
  
  // Estado inicial para los campos, incluyendo nextContact
  const [formData, setFormData] = useState({
    nombre: client.nombre,
    email: client.email,
    phone: client.phone,
    address: client.address,
    contactPreference: client.contactPreference,
    propertyInterest: client.propertyInterest,
    budget: client.budget,
    status: client.status,
    notes: client.notes,
    nextContact: client.nextContact || '' // Nuevo campo para Siguiente Contacto
  });

  // Manejo de cambios en los inputs
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Función para actualizar los datos del cliente
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const clientRef = doc(db, 'clientes', client.id);
      await updateDoc(clientRef, formData);
      alert('Cliente actualizado con éxito');
      onClientUpdated();
    } catch (error) {
      console.error('Error al actualizar cliente:', error);
      alert('Hubo un error al actualizar el cliente');
    }
  };

  return (
    <div className="edit-client-form">
      <h2>Editar Cliente</h2>
      <form onSubmit={handleUpdate}>
        <div className="form-group">
          <label>Nombre Completo</label>
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Teléfono</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="form-group">
          <label>Dirección</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Preferencia de Contacto</label>
          <select
            name="contactPreference"
            value={formData.contactPreference}
            onChange={handleChange}
            className="form-control"
          >
            <option value="email">Email</option>
            <option value="whatsapp">WhatsApp</option>
          </select>
        </div>

        <div className="form-group">
          <label>Propiedades de Interés</label>
          <input
            type="text"
            name="propertyInterest"
            value={formData.propertyInterest}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Presupuesto</label>
          <input
            type="number"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Estado del Cliente</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="form-control"
          >
            <option value="activo">Activo</option>
            <option value="negociacion">En Negociación</option>
            <option value="cerrado">Cerrado</option>
            <option value="perdido">Perdido</option>
          </select>
        </div>

        <div className="form-group">
          <label>Notas</label>
          <textarea
            name="notes"
            value={formData.notes}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        {/* Nuevo campo para Siguiente Contacto */}
        <div className="form-group">
          <label>Siguiente Contacto:</label>
          <input
            type="date"
            name="nextContact" // Asegúrate de que el name coincida con el estado
            value={formData.nextContact}
            onChange={handleChange}
            className="form-control"
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Actualizar Cliente
        </button>
      </form>
    </div>
  );
};

export default EditClient;
