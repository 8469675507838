import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebaseConfig.js';
import EditeProperty from './EditeProperty.js';
import './AdminProperties.css';

const AdminPropertiesIA = ({ properties }) => {
  const [sortedProperties, setSortedProperties] = useState(properties);
  const [sortOrder, setSortOrder] = useState('asc');
  const [allProperties, setAllProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 10;

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [bajaReason, setBajaReason] = useState('');
  const [salePrice, setSalePrice] = useState('');

  useEffect(() => {
    setSortedProperties(properties);
    setAllProperties(properties);
    setLoading(false);
  }, [properties]);

  const handleSort = (field) => {
    const sorted = [...sortedProperties].sort((a, b) => {
      if (field === 'precio') {
        return sortOrder === 'asc' ? a[field] - b[field] : b[field] - a[field];
      } else {
        return sortOrder === 'asc' ? a[field].localeCompare(b[field]) : b[field].localeCompare(a[field]);
      }
    });
    setSortedProperties(sorted);
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  useEffect(() => {
    setFilteredProperties(
      allProperties.filter((property) =>
        (property.direccion?.toLowerCase().includes(searchTerm.toLowerCase()) || '') ||
        (property.descrip?.toLowerCase().includes(searchTerm.toLowerCase()) || '')
      )
    );
  }, [searchTerm, allProperties]);

  const handleDelete = (property) => {
    setSelectedProperty(property);
    setShowDeleteModal(true);
  };

  const handleBajaSubmit = async () => {
    try {
      const updatedPrice = bajaReason === 'vendido' ? salePrice : '0';
      await updateDoc(doc(db, 'properties', selectedProperty.id), {
        venta: updatedPrice,
        motivo: bajaReason,
      });
      setAllProperties(allProperties.filter((prop) => prop.id !== selectedProperty.id));
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error updating property:', error);
    }
  };

  const handleEdit = (property) => {
    setSelectedProperty(property);
    setShowEditModal(true);
  };

  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

  const nextPage = () => setCurrentPage((prev) => prev + 1);
  const prevPage = () => setCurrentPage((prev) => prev - 1);

  if (loading) {
    return <p>Cargando propiedades...</p>;
  }

  return (
    <div>
      <h3>Lista de Propiedades</h3>

      <input
        type="text"
        className="inputMolis"
        placeholder="Buscar propiedades..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <table>
        <thead>
          <tr>
            <th>Imagen</th>
            <th onClick={() => handleSort('direccion')}>Dirección</th>
            <th onClick={() => handleSort('publicacion')}>Publicación</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {currentProperties.map((property) => (
            <tr key={property.id}>
              <td>
                {property.archivos && property.archivos.length > 0 ? (
                  <img src={property.archivos[0].fileURL} alt="Imagen de la propiedad" className="thumbnail" />
                ) : (
                  <span>No image available</span>
                )}
              </td>
              <td>{property.direccion}</td>
              <td>{property.publicacion}</td>
              <td>
                <button onClick={() => handleEdit(property)}>Editar</button>
                <button onClick={() => handleDelete(property)}>Eliminar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination
        propertiesPerPage={propertiesPerPage}
        totalProperties={filteredProperties.length}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
      />

      {showEditModal && (
        <EditeProperty
          property={selectedProperty}
          closeModal={() => setShowEditModal(false)}
          onSave={(updatedProperty) => {
            setAllProperties(
              allProperties.map((prop) =>
                prop.id === updatedProperty.id ? updatedProperty : prop
              )
            );
            setShowEditModal(false);
          }}
        />
      )}

      {showDeleteModal && (
        <div className="modal">
          <div className="modal-content">
            <h4>Baja de Propiedad</h4>
            <p>Motivo de baja:</p>
            <select
              value={bajaReason}
              onChange={(e) => setBajaReason(e.target.value)}
              className="form-control"
            >
              <option value="">Seleccione una opción</option>
              <option value="retirado">Se retira del mercado</option>
              <option value="vendido">Se vendió</option>
            </select>
            {bajaReason === 'vendido' && (
              <div>
                <p>Precio de venta:</p>
                <input
                  type="number"
                  className="form-control"
                  value={salePrice}
                  onChange={(e) => setSalePrice(e.target.value)}
                />
              </div>
            )}
            <button onClick={handleBajaSubmit}>Confirmar baja</button>
            <button onClick={() => setShowDeleteModal(false)}>Cancelar</button>
          </div>
        </div>
      )}
    </div>
  );
};

const Pagination = ({ propertiesPerPage, totalProperties, nextPage, prevPage, currentPage }) => {
  const totalPages = Math.ceil(totalProperties / propertiesPerPage);

  return (
    <nav className="pagination-nav">
      <ul className="pagination">
        {currentPage > 1 && (
          <li className="page-item">
            <button onClick={prevPage} className="page-link">
              Anterior
            </button>
          </li>
        )}
        {currentPage < totalPages && (
          <li className="page-item">
            <button onClick={nextPage} className="page-link">
              Siguiente
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default AdminPropertiesIA;
