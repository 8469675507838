// Mensajes.js
import React from 'react';

const Mensajes = () => {
  return (
    <div className="mensajes-container">
      <h2>PRÓXIMAMENTE</h2>
    </div>
  );
};

export default Mensajes;

/* Código comentado por ahora:

import React, { useState, useEffect } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig.js';
import './Mensajes.css';

const Mensajes = () => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [response, setResponse] = useState('');
  const [showReplyModal, setShowReplyModal] = useState(false);

  // Obtener mensajes desde Firestore
  useEffect(() => {
    const fetchMessages = async () => {
      const messagesCollection = collection(db, 'messages');
      const messageSnapshot = await getDocs(messagesCollection);
      const messageList = messageSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messageList);
    };
    fetchMessages();
  }, []);

  // Manejar la apertura del modal para responder
  const handleReply = (message) => {
    setSelectedMessage(message);
    setShowReplyModal(true);
  };

  // Manejar la respuesta al mensaje
  const handleSendReply = async () => {
    if (selectedMessage && response.trim()) {
      const messageDoc = doc(db, 'messages', selectedMessage.id);
      await updateDoc(messageDoc, {
        response,
        status: 'respondido',
      });
      // Actualizar la lista de mensajes
      setMessages(messages.map((msg) => 
        msg.id === selectedMessage.id ? { ...msg, response, status: 'respondido' } : msg
      ));
      setShowReplyModal(false);
      setResponse('');
    }
  };

  return (
    <div className="mensajes-container">
      <h3>Mensajes Recibidos</h3>
      <table className="messages-table">
        <thead>
          <tr>
            <th>Remitente</th>
            <th>Tipo</th>
            <th>Mensaje</th>
            <th>Fecha</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message) => (
            <tr key={message.id}>
              <td>{message.sender}</td>
              <td>{message.type}</td>
              <td>{message.content}</td>
              <td>{new Date(message.timestamp?.seconds * 1000).toLocaleString()}</td>
              <td>{message.status}</td>
              <td>
                <button onClick={() => handleReply(message)}>Responder</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

  

export default Mensajes;

*/
