import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig.js'; // Asegúrate de que la ruta sea correcta
import { collection, addDoc, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore'; // Importa updateDoc
import { onAuthStateChanged } from 'firebase/auth';

const CrearInm = () => {
  const [nombre, setNombre] = useState('');
  const [registro, setRegistro] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [propietarioNombre, setPropietarioNombre] = useState('');
  const [usuarioId, setUsuarioId] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null); // Estado para el usuario actual
  const [isAdmin, setIsAdmin] = useState(false); // Estado para verificar si es admin

  useEffect(() => {
    // Escuchar los cambios de autenticación para obtener el usuario actual
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        // Verificar si el usuario tiene el rol de admin
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists() && userDoc.data().role === 'admin') {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } else {
        setCurrentUser(null);
        setIsAdmin(false);
      }
    });

    return () => unsubscribe(); // Limpiar la suscripción
  }, []);

  useEffect(() => {
    // Función para obtener los usuarios de Firestore
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const usersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        fullName: doc.data().fullName,
      }));
      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  const handlePropietarioChange = (e) => {
    const value = e.target.value;
    setPropietarioNombre(value);

    if (value.trim() !== "") {
      const filtered = users.filter(user =>
        user.fullName && user.fullName.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers([]);
    }
  };

  const handleUserSelect = (user) => {
    setPropietarioNombre(user.fullName);
    setUsuarioId(user.id);
    setFilteredUsers([]);
  };

  const cambiarRolAInmobiliaria = async (userId, inmobiliariaId) => {
    try {
      const userRef = doc(db, 'users', userId);
  
      // Actualizar el rol y añadir campos separados para el ID de la inmobiliaria
      await updateDoc(userRef, { 
        role: 'inmobiliaria',
        inmobiliaria: inmobiliariaId, // Ahora se guarda el ID de la inmobiliaria
      });
  
      console.log('Rol del usuario actualizado a inmobiliaria con ID de la inmobiliaria.');
    } catch (error) {
      console.error('Error al actualizar el rol del usuario:', error);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isAdmin) {
      alert('Solo los administradores pueden crear una inmobiliaria.');
      return;
    }
  
    try {
      // Crear la inmobiliaria y obtener su ID
      const inmobiliariaRef = await addDoc(collection(db, 'inmobiliarias'), {
        nombre,
        registro,
        ciudad,
        usuarioId, // Almacenar el userId del propietario seleccionado
      });

      alert('Inmobiliaria creada exitosamente');
  
      // Cambiar el rol del propietario a inmobiliaria y usar el ID de la nueva inmobiliaria
      if (usuarioId) {
        await cambiarRolAInmobiliaria(usuarioId, inmobiliariaRef.id);
      }
  
      // Limpiar los campos del formulario
      setNombre('');
      setRegistro('');
      setCiudad('');
      setPropietarioNombre('');
      setUsuarioId('');
    } catch (error) {
      console.error('Error al crear la inmobiliaria: ', error);
      alert('Error al crear la inmobiliaria');
    }
  };
  
  return (
    <div className="add-property"> 
      <form onSubmit={handleSubmit}> 
        <h3>Crear Inmobiliaria</h3>
        
        <div className="form-group">
          <label>Nombre de la Inmobiliaria:</label>
          <input 
            type="text" 
            className="form-control" 
            value={nombre} 
            onChange={(e) => setNombre(e.target.value)} 
            required 
          />
        </div>

        <div className="form-group">
          <label>Registro:</label>
          <input 
            type="text" 
            className="form-control" 
            value={registro} 
            onChange={(e) => setRegistro(e.target.value)} 
            required 
          />
        </div>

        <div className="form-group">
          <label>Ciudad:</label>
          <input 
            type="text" 
            className="form-control" 
            value={ciudad} 
            onChange={(e) => setCiudad(e.target.value)} 
            required 
          />
        </div>

        <div className="form-group">
          <label>Propietario</label>
          <input
            type="text"
            className="form-control"
            value={propietarioNombre}
            onChange={handlePropietarioChange}
            placeholder="Nombre del propietario"
            autoComplete="off"
            required
          />
          {filteredUsers.length > 0 && (
            <ul className="user-suggestions">
              {filteredUsers.slice(0, 5).map((user) => (
                <li key={user.id} onClick={() => handleUserSelect(user)}>
                  {user.fullName}
                </li>
              ))}
            </ul>
          )}
        </div>

        <button type="submit">Crear Inmobiliaria</button>
      </form>
    </div>  
  );
};

export default CrearInm;
